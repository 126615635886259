import {Box, CircularProgress} from "@mui/material";

export default function SimpleCircularProgress({width = "auto", height = "100vh"}) {
    return (
        <Box sx={{
            width, height,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <CircularProgress color={"primary"}/>
        </Box>
    );
}
