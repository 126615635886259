import {Enum} from "../../../../utils/enum.utils";
import {useHasRole} from "../../../../shared/hooks/useAuth";

export const EventRoles = Enum({
    EVENT_EDIT: {label: "Editer un événement"},
    EVENT_ADMIN: {label: "Administrer un événement"},
    EVENT_VIEW: {label: "Créer un événement"},
    TICKET_SALE: {label: "Vente de ticket"},
    TICKET_VALIDATE: {label: "Valider un événement"},
});

export const useEventRoles = () => {

    const canAdmin = useHasRole([EventRoles.EVENT_ADMIN.name]);

    const canDelete = useHasRole([EventRoles.EVENT_ADMIN.name]);

    const canSell = useHasRole([
        EventRoles.EVENT_ADMIN.name,
        EventRoles.EVENT_EDIT.name
    ]);

    const canCreate = useHasRole([
        EventRoles.EVENT_ADMIN.name,
        EventRoles.EVENT_EDIT.name
    ]);

    const canUpdate = useHasRole([
        EventRoles.EVENT_ADMIN.name,
        EventRoles.EVENT_EDIT.name
    ]);

    const canView = useHasRole([
        EventRoles.EVENT_ADMIN.name,
        EventRoles.EVENT_EDIT.name,
        EventRoles.EVENT_VIEW.name
    ]);

    const canValidate = useHasRole([
        EventRoles.TICKET_VALIDATE.name,
    ]);

    return {canAdmin, canCreate, canSell, canUpdate, canDelete, canView, canValidate};
}
