import AppLayout from "./AppLayout";
import ThemeConfig from "../../theme";
import GlobalStyles from "../../theme/globalStyles";
import ThemePrimaryColor from "../../shared/components/ThemePrimaryColor";
import ThemeLocalization from "../../shared/components/ThemeLocalization";
import PageLoadingIndicator from "../../shared/components/PageLoadingIndicator";
import {ScrollToTop, BaseOptionChartStyle, NotistackProvider} from "../../shared/components";

export default function App({children}) {
    return (
        <ThemeConfig>
            <ThemePrimaryColor>
                <ThemeLocalization>
                    <NotistackProvider>
                        <GlobalStyles/>
                        <BaseOptionChartStyle/>
                        <ScrollToTop/>
                        <PageLoadingIndicator/>
                        <AppLayout>
                            {children}
                        </AppLayout>
                    </NotistackProvider>
                </ThemeLocalization>
            </ThemePrimaryColor>
        </ThemeConfig>
    );
}

