import {CommonDoc} from "../../../../shared/services/firebase/domain.firebase";

export const Event = {
    folderName: "events",
    colNamePending: "pending_events",
    colNameValidated: "validated_events",

    ...CommonDoc,
    keys: "keys",
    name: "name",
    departure: "departure",
    startingDate: "startingDate",
    description: "description",
    seatCount: "seatCount",
    remainingSeatCount: "remainingSeatCount",
}

export const Like = {
    colName: "likes",

    ...CommonDoc,
}
export const Comment = {
    colName: "comments",

    ...CommonDoc,
}

export const TicketOfferStates = {
    activated: "activated",
    deActivated: "deActivated",
    new: "new",
    saved: "saved",
}
