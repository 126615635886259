import {useQuery} from "react-query";
import {useRouter} from "next/router";
import {useRecoilState} from "recoil";
import {useMessage} from "./useMessage";
import {useCallback, useEffect, useState} from "react";

export const useOpen = (initialValue = false) => {
    const [open, setOpen] = useState(initialValue);

    const onOpen = useCallback(() => setOpen(true), [setOpen]);

    const onClose = useCallback(() => setOpen(false), [setOpen]);

    return {open, onOpen, onClose}
}

export const useShow = (initialValue = false) => {
    const {open, onOpen, onClose} = useOpen(initialValue);

    return {show: open, onShow: onOpen, onHidden: onClose}
}

export const useHover = () => {
    const [hover, setHover] = useState(false);

    const onHover = () => setHover(true);
    const onLeave = () => setHover(false);

    const mouseEvents = {onMouseEnter: onHover, onMouseLeave: onLeave};

    return {hover, onHover, onLeave, mouseEvents}
}

export const useRecoilReactQuery = ({recoilState, queryKey, queryFn, queryOption}) => {
    const [state, setState] = useRecoilState(recoilState);
    const result = useQuery(queryKey, queryFn, queryOption);

    useEffect(() => {
        const {data: queryData, status: queryStatus} = result;
        const {data: stateData, status: stateStatus} = (state || {});
        if (stateData === queryData && stateStatus === queryStatus) return;

        setState(result);
    }, [state, result, setState]);

    return result;
}


export const useEntityCount = (entity, countFn, staleTime = 5000) => {
    const queryKey = [`GET_${entity}_COUNT`];
    const queryFn = countFn ?? (() => 0);
    const {data: count, ...others} = useQuery(queryKey, queryFn, {staleTime});
    return {count, ...others};
}


export const useForwardIfNotFound = ({isLoading, data, link, notFoundMessage}) => {

    const {push, isReady} = useRouter();
    const {showError} = useMessage();

    useEffect(() => {
        if (isReady && !isLoading && !data) {
            showError(notFoundMessage);
            push(link).then();
        }
    }, [isLoading, data, link, push, showError, notFoundMessage]);
}

