import {Enum} from "../../../../utils/enum.utils";
import {useHasRole} from "../../../../shared/hooks/useAuth";

export const UserGroupRoles = Enum({
    USER_GROUP_EDIT: {label: "Editer un groupe d'utilisateur"},
    USER_GROUP_ADMIN: {label: "Administrer un groupe d'utilisateur"},
    USER_GROUP_VIEW: {label: "Créer un groupe d'utilisateur"},
});

export const useUserGroupRoles = () => {

    const canAdmin = useHasRole([UserGroupRoles.USER_GROUP_ADMIN.name]);

    const canDelete = useHasRole([UserGroupRoles.USER_GROUP_ADMIN.name]);

    const canCreate = useHasRole([
        UserGroupRoles.USER_GROUP_ADMIN.name,
        UserGroupRoles.USER_GROUP_EDIT.name
    ]);

    const canUpdate = useHasRole([
        UserGroupRoles.USER_GROUP_ADMIN.name,
        UserGroupRoles.USER_GROUP_EDIT.name
    ]);

    const canView = useHasRole([
        UserGroupRoles.USER_GROUP_ADMIN.name,
        UserGroupRoles.USER_GROUP_EDIT.name,
        UserGroupRoles.USER_GROUP_VIEW.name
    ]);

    return {canAdmin, canCreate, canUpdate, canDelete, canView};
}
