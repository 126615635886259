import {Enum} from "../../../../utils/enum.utils";
import {useHasRole} from "../../../../shared/hooks/useAuth";

export const HomePageRoles = Enum({
    HOME_PAGE_EDIT: {label: "Editer la configuration générale"},
    HOME_PAGE_ADMIN: {label: "Administrer la configuration générale"},
    HOME_PAGE_VIEW: {label: "Créer la configuration générale"},
});

export const useHomePageRoles = () => {

    const canAdmin = useHasRole([HomePageRoles.HOME_PAGE_ADMIN.name]);

    const canDelete = useHasRole([HomePageRoles.HOME_PAGE_ADMIN.name]);

    const canCreate = useHasRole([
        HomePageRoles.HOME_PAGE_ADMIN.name,
        HomePageRoles.HOME_PAGE_EDIT.name
    ]);

    const canUpdate = useHasRole([
        HomePageRoles.HOME_PAGE_ADMIN.name,
        HomePageRoles.HOME_PAGE_EDIT.name
    ]);

    const canView = useHasRole([
        HomePageRoles.HOME_PAGE_ADMIN.name,
        HomePageRoles.HOME_PAGE_EDIT.name,
        HomePageRoles.HOME_PAGE_VIEW.name
    ]);

    return {canAdmin, canCreate, canUpdate, canDelete, canView};
}
