import {useRoleChecker} from "../../../shared/hooks/useAuth";
import sidebarConfig from "./SidebarConfig";

export const useNavConfig = () => {
    const {hasRole} = useRoleChecker();

    return filterNavConfig(hasRole, sidebarConfig);
}

function filterNavConfig(hasRole, navConfig) {
    const [dashboard, ...others] = navConfig;

    const filtered = others
        .map(navItem => mapNavItem(hasRole, navItem))
        .filter(navItem => navItem.items.length > 0);

    return [dashboard, ...filtered];
}

function mapNavItem(hasRole, navItem) {
    const items = navItem
        .items?.map(item => mapItem(hasRole, item))
        .filter(item => item.children.length > 0) ?? [];

    return {...navItem, items}
}

function mapItem(hasRole, item) {
    const children = item.children?.filter(({roles}) => {
        return hasRole && hasRole(roles);
    }) ?? [];

    return {...item, children}
}

