import {useJsApiLoader} from "@react-google-maps/api";
import {createContext, useContext, useMemo} from "react";

export const GMapsLoadScriptContext = createContext({isLoaded: false, loadError: null, googleMapsApiKey: null});
export const useGMapsLoadScript = () => useContext(GMapsLoadScriptContext);

const libraries = ["places"];

export default function GMapsLoadScriptProvider({children}) {

    const nonce = useMemo(() => "gmaps-api", []);

    const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const {isLoaded, loadError} = useJsApiLoader({
        googleMapsApiKey,
        libraries,
        nonce,
    });

    return (
        <GMapsLoadScriptContext.Provider value={{isLoaded, loadError, googleMapsApiKey}}>
            {children}
        </GMapsLoadScriptContext.Provider>
    );
}

