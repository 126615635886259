import {Enum} from "../../../../utils/enum.utils";
import {useHasRole} from "../../../../shared/hooks/useAuth";


export const TicketRoles = Enum({
    TICKET_EDIT: {label: "Editer un ticket"},
    TICKET_ADMIN: {label: "Administrer un ticket"},
    TICKET_VIEW: {label: "Créer un ticket"},
    TICKET_VALIDATE: {label: "Valider un ticket"},
});

export const useTicketRoles = () => {

    const canAdmin = useHasRole([TicketRoles.TICKET_ADMIN.name]);

    const canDelete = useHasRole([TicketRoles.TICKET_ADMIN.name]);

    const canCreate = useHasRole([
        TicketRoles.TICKET_ADMIN.name,
        TicketRoles.TICKET_EDIT.name
    ]);

    const canUpdate = useHasRole([
        TicketRoles.TICKET_ADMIN.name,
        TicketRoles.TICKET_EDIT.name
    ]);

    const canValidate = useHasRole([
        TicketRoles.TICKET_VALIDATE.name,
    ]);

    const canView = useHasRole([
        TicketRoles.TICKET_ADMIN.name,
        TicketRoles.TICKET_EDIT.name,
        TicketRoles.TICKET_VIEW.name
    ]);

    return {canAdmin, canCreate, canUpdate, canDelete, canView, canValidate};
}
