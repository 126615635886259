import {useState} from "react";
import {alpha, styled} from "@mui/material/styles";
import {ChevronRight, ExpandMore, FiberManualRecord} from "@mui/icons-material";
import {Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";

import {useHover} from "../../../shared/hooks/hooks.utils";
import SimpleLink from "../../../shared/components/SimpleLink";

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(
    ({theme}) => ({
        ...theme.typography.body2,
        height: 48,
        position: 'relative',
        textTransform: 'capitalize',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2.5),
        color: theme.palette.text.secondary,
        '&:before': {
            top: 0,
            right: 0,
            width: 3,
            bottom: 0,
            content: "''",
            display: 'none',
            position: 'absolute',
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
            backgroundColor: theme.palette.primary.main
        }
    })
);

const ListItemIconStyle = styled(ListItemIcon)({
    width: 22,
    height: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});

// ----------------------------------------------------------------------

const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    '&:before': {display: 'block'},
    backgroundColor: theme => alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    // backgroundColor: "red"
};

const activeSubStyle = {
    color: "text.primary",
    fontWeight: "fontWeightMedium"
};

export default function NavItem({item, isShow, routeMatch}) {
    if (!item) return null;

    if (!item.children) {
        return <NavItemSingle item={item} isShow={isShow} routeMatch={routeMatch}/>;
    }
    return <NavItemWithChildren item={item} isShow={isShow} routeMatch={routeMatch}/>
}

function NavItemSingle({item, isShow, routeMatch}) {
    const {hover, mouseEvents} = useHover();

    const {title, path, icon, info, exact, moreMenu} = item;
    const isActiveRoot = routeMatch({path, exact});


    return (
        <Box {...mouseEvents} sx={{display: "flex", alignItems: "center"}}>
            <Box flexGrow={1}>
                <SimpleLink href={path}>
                    <ListItemStyle sx={{...(isActiveRoot && activeRootStyle)}}>
                        <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
                        {isShow && (
                            <>
                                <ListItemText disableTypography primary={title}/>
                                {info && info}
                            </>
                        )}
                    </ListItemStyle>
                </SimpleLink>
            </Box>
            {hover && moreMenu}
        </Box>
    );
}

function NavItemWithChildren({item, isShow, routeMatch}) {
    const {hover, mouseEvents} = useHover();

    const {title, path, icon, info, children, exact, moreMenu} = item;
    const isActiveRoot = routeMatch({path, exact});

    const [open, setOpen] = useState(isActiveRoot);

    const handleOpen = () => setOpen(!open);

    return (
        <>
            <ListItemStyle
                {...mouseEvents}
                onClick={handleOpen} sx={{...(isActiveRoot && activeRootStyle)}}
            >
                <ListItemIconStyle>{icon && icon}</ListItemIconStyle>

                {isShow && (
                    <>
                        <ListItemText disableTypography primary={title}/>
                        {info && info}
                        <>
                            {hover && moreMenu}
                            {open ? <ExpandMore/> : <ChevronRight/>}
                        </>

                    </>
                )}
            </ListItemStyle>

            {isShow && (
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {children.map((item) => (
                            <NavItemChild key={item.title} item={item} routeMatch={routeMatch}/>
                        ))}
                    </List>
                </Collapse>
            )}
        </>
    );
}

function NavItemChild({item, routeMatch}) {
    const {hover, mouseEvents} = useHover();

    const {title, path, moreMenu} = item;
    const isActiveSub = routeMatch({path});

    return (
        <Box {...mouseEvents} sx={{display: "flex", alignItems: "center"}}>
            <Box flexGrow={1}>
                <SimpleLink href={path}>
                    <ListItemStyle
                        sx={{...(isActiveSub && activeSubStyle)}}
                    >
                        <ListItemIconStyle>
                            <FiberManualRecord sx={{
                                fontSize: 6,
                                color: "text.disabled",
                                transition: (theme) => theme.transitions.create('all'),
                                ...(isActiveSub && {
                                    transform: "scale(2)",
                                    color: "primary.main"
                                })
                            }}/>
                        </ListItemIconStyle>
                        <ListItemText disableTypography primary={title}/>
                    </ListItemStyle>
                </SimpleLink>
            </Box>
            {hover && moreMenu}
        </Box>
    );
}

