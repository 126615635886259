import {Enum} from "../../../../utils/enum.utils";
import {useHasRole} from "../../../../shared/hooks/useAuth";

export const UserLogsRoles = Enum({
    USER_LOGS_VIEW: {label: "Voir les logs"},
});

export const useUserLogsRoles = () => {

    const canView = useHasRole([UserLogsRoles.USER_LOGS_VIEW.name]);

    return {canView};
}
