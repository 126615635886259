import {Enum} from "../../../../utils/enum.utils";
import {useRoleChecker} from "../../../../shared/hooks/useAuth";


export const ContactUsMessageInfoRoles = Enum({
    CONTACT_US_MESSAGE_INFO_EDIT: {label: "Editer un message de contacter nous"},
    CONTACT_US_MESSAGE_INFO_ADMIN: {label: "Administrer un message de contacter nous"},
    CONTACT_US_MESSAGE_INFO_VIEW: {label: "Créer un message de contacter nous"},
});

export const useContactUsMessageInfoRoles = () => {

    const canAdmin = useRoleChecker([ContactUsMessageInfoRoles.CONTACT_US_MESSAGE_INFO_ADMIN.name]);

    const canDelete = useRoleChecker([ContactUsMessageInfoRoles.CONTACT_US_MESSAGE_INFO_ADMIN.name]);

    const canCreate = useRoleChecker([
        ContactUsMessageInfoRoles.CONTACT_US_MESSAGE_INFO_ADMIN.name,
        ContactUsMessageInfoRoles.CONTACT_US_MESSAGE_INFO_EDIT.name
    ]);

    const canUpdate = useRoleChecker([
        ContactUsMessageInfoRoles.CONTACT_US_MESSAGE_INFO_ADMIN.name,
        ContactUsMessageInfoRoles.CONTACT_US_MESSAGE_INFO_EDIT.name
    ]);

    const canView = useRoleChecker([
        ContactUsMessageInfoRoles.CONTACT_US_MESSAGE_INFO_ADMIN.name,
        ContactUsMessageInfoRoles.CONTACT_US_MESSAGE_INFO_EDIT.name,
        ContactUsMessageInfoRoles.CONTACT_US_MESSAGE_INFO_VIEW.name
    ]);

    return {canAdmin, canCreate, canUpdate, canDelete, canView};
}
