import {useRouter} from "next/router";
import {useContext, useMemo} from "react";
import {AuthContext} from "../contexts/AuthContext";

// ----------------------------------------------------------------------

const useAuth = () => useContext(AuthContext);

export default useAuth;

export const useRoleChecker = () => {
    const {hasRole, hasNotRole, roles} = useAuth();
    return {hasRole, hasNotRole};
}

export const useHasRole = (roles) => {
    const {hasRole} = useAuth();

    return useMemo(() => {
        if (!roles?.length) return true;

        return hasRole(roles);
    }, [roles, hasRole]);
}

export const usePathnameStartWith = (routeRoot) => {
    const {pathname} = useRouter();

    return (pathname.indexOf(routeRoot) === 0);
}

export const useIsAuthPath = () => {
    return usePathnameStartWith('/auth/');
}
