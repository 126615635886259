import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
// material
import {CssBaseline} from '@mui/material';
import {ThemeProvider, createTheme, StyledEngineProvider} from '@mui/material/styles';
import {frFR} from '@mui/material/locale';
// hooks
import useSettings from '../shared/hooks/useSettings';
//
import shape from './shape';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, {customShadows} from './shadows';

// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
    children: PropTypes.node
};

export default function ThemeConfig({children}) {
    const {themeMode, themeDirection} = useSettings();
    const isLight = themeMode === 'light';

    const themeOptions = useMemo(
        () => ({
            palette: isLight ? {...palette.light, mode: 'light'} : {...palette.dark, mode: 'dark'},
            shape,
            typography,
            breakpoints,
            direction: themeDirection,
            shadows: isLight ? shadows.light : shadows.dark,
            customShadows: isLight ? customShadows.light : customShadows.dark
        }),
        [isLight, themeDirection]
    );

    const theme = createTheme(themeOptions, frFR);
    theme.components = componentsOverride(theme);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
