import {unionBy} from "lodash";
import {useEffect} from "react";
import {useQuery} from "react-query";
import {atom, useRecoilState} from "recoil";
import {UserGroupRole} from "./user.domains";
import {ko, ok} from "../../../../utils/service.utils";
import useAuth from "../../../../shared/hooks/useAuth";
import {Event} from "../../general/events/event.domains";
import {useEntityCount} from "../../../../shared/hooks/hooks.utils";
import {limit, orderBy, query as _query, updateDoc, where} from "firebase/firestore";
import {initialSearchQueryData} from "../../../../shared/services/firebase/firebase.cache.store";
import {
    auditorUpdate,
    collectionGroupRef,
    crudService,
    execQueryForList,
    service
} from "../../../../shared/services/firebase/db.firebase";

const colName = "users";

export const {colRef, docRef, create, update, remove, findById, findAll, count, newDocRef} = crudService(colName);

const usersState = atom({
    key: "users.state",
    default: {...initialSearchQueryData, userType: "all"},
});


export async function updateUser(id, entity) {
    try {
        await update(id, auditorUpdate(entity));
        return ok(id, "Modification reussie avec succès");
    } catch (e) {
        return ko("Erreur lors de la modification de l'utilisateur");
    }
}

export const addGroups = async (userId, groups) => {
    if (!userId) return ko("Impossible d'ajouter des groupes d'utilisateur à un utilisateur nul");
    if (!groups?.length) return ko("Impossible d'ajouter une liste de groupes d'utilisateur vide'");
    try {
        console.log("Add Groups: ", {userId, groups});
        const user = await findById(userId);
        if (!user) return ko("Cet utilisateur n'existe pas");
        const currentGroups = user.groups ?? [];
        const item = {groups: [...currentGroups, ...groups]};
        await service(colName).update(docRef(userId), item);
        const s = groups.length > 1 ? "s" : "";
        return ok(userId, `Groupe${s} d'utilisateur ajouté${s} avec succès`);
    } catch (e) {
        return ko("Erreur lors de la l'ajout de role");
    }
}

export const removeGroups = async (userId, groups) => {
    if (!userId) return ko("Impossible d'ajouter des groupes d'utilsateur à un utilisateur nul");
    if (!groups?.length) return ko("Impossible d'ajouter une liste de groupes d'utilsateur vide'");
    try {
        const user = await findById(userId);
        if (!user) return ko("Cet utilisateur n'existe pas");
        if (!user.groups) return ko("Cet utilisateur n'a pas de groupes d'utilisateur");

        const s = groups.length > 1 ? "s" : "";
        const updatedGroups = user.groups.filter(id => !groups.includes(id));
        await service(colName).update(docRef(userId), {groups: updatedGroups});
        return ok(userId, `Groupe${s} d'utilisateur retiré${s} avec succès`);
    } catch (e) {
        return ko("Erreur lors de la l'ajout de role");
    }
}

export const findUserAndAuthorities = async (userId) => {
    try {
        if (!userId) return {};

        const user = await findById(userId);
        if (!user) return {};

        if (!user.groups?.length) return {user, roles: []};

        const ref = collectionGroupRef(UserGroupRole.colName);

        const _roles = await execQueryForList(_query(ref, where(UserGroupRole.groupId, "in", user.groups)));
        const roles = unionBy(_roles, ({name}) => name);

        return {user, roles};
    } catch (error) {
        return {};
    }
}

export async function assignUser(userId, userGroups) {
    try {
        if (!userId) return ko("Cet utilisateur est null");
        await updateDoc(docRef(userId), auditorUpdate({groups: [...userGroups], type: "organisation"}));
        return ok(userId, "Affectation effectuée avec succès");
    } catch (e) {
        return ko("Erreur lors de la création de l'utilisateur");
    }
}

export const useSearchUsers = () => {
    const [queryData, setQueryData] = useRecoilState(usersState);
    const {query, userType, size} = queryData;

    const queryKey = [colName, "SEARCH_USERS", query, userType];

    const queryFn = async () => {
        let fetchQuery = colRef;
        if (query) {
            fetchQuery = _query(fetchQuery, where(Event.keys, "array-contains", query));
        }
        if (userType !== "all") {
            fetchQuery = _query(fetchQuery, where("groups", "!=", undefined));
        }
        fetchQuery = _query(fetchQuery, limit(size));

        return await execQueryForList(fetchQuery);
    }

    const {data, ...others} = useQuery(queryKey, queryFn);

    useEffect(() => {
        if (!data) return;
        setQueryData(queryData => ({...queryData, data}));
    }, [data]);

    const setQuery = (query) => {
        setQueryData(queryData => ({...queryData, query}));
    }

    const setUserType = (userType) => {
        setQueryData(queryData => ({...queryData, userType}));
    }

    return {...queryData, ...others, setQuery, setUserType}
}

export const useFindById = (id) => {
    const queryKey = ["FIND_USER_BY_ID", id];
    const queryFn = () => {
        if (!id) return null;
        return findById(id);
    }
    return useQuery(queryKey, queryFn, {});
}

export const useUserCount = () => {
    return useEntityCount("USER", count);
}

export const useFindAllUsers = () => {
    const queryKey = ["FIND_ALL_USERS"];
    const queryFn = async () => {
        try {
            const _data = await execQueryForList(_query(colRef, orderBy("order", "asc")));
            return _data;
        } catch (e) {
            return null;
        }
    }
    return useQuery(queryKey, queryFn, {});
}

export const useUserAuthorities = () => {
    const {user: authUser, ...others} = useAuth();

    const userId = authUser?.uid;

    const queryKey = [colName, "user.n.authorities", userId];
    const queryFn = () => findUserAndAuthorities(userId);
    const {data} = useQuery(queryKey, queryFn);

    const {user, roles} = data ?? {};
    return {user, roles, ...others};
}

export const useFindByGroupId = (groupId) => {
    const queryKey = [colName, "by.group.id"];
    const queryFn = async () => {
        if (!groupId) return;
        const fetchQuery = _query(colRef, where("groups", "array-contains", groupId));
        const users = await execQueryForList(fetchQuery)
        return {users};
    }
    const {data} = useQuery(queryKey, queryFn);
    const {users} = data ?? [];
    return {users};
}
