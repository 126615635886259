import {Enum} from "../../../../utils/enum.utils";
import {useHasRole} from "../../../../shared/hooks/useAuth";

export const UserRoles = Enum({
    USER_EDIT: {label: "Editer un utilisateur"},
    USER_ADMIN: {label: "Administrer un utilisateur"},
    USER_VIEW: {label: "Créer un utilisateur"},
});

export const useUserRoles = () => {

    const canAdmin = useHasRole([UserRoles.USER_ADMIN.name]);

    const canDelete = useHasRole([UserRoles.USER_ADMIN.name]);

    const canCreate = useHasRole([
        UserRoles.USER_ADMIN.name,
        UserRoles.USER_EDIT.name
    ]);

    const canUpdate = useHasRole([
        UserRoles.USER_ADMIN.name,
        UserRoles.USER_EDIT.name
    ]);

    const canView = useHasRole([
        UserRoles.USER_ADMIN.name,
        UserRoles.USER_EDIT.name,
        UserRoles.USER_VIEW.name
    ]);

    return {canAdmin, canCreate, canUpdate, canDelete, canView};
}
