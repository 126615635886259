import {Menu} from "@mui/icons-material";
import {alpha, styled} from "@mui/material/styles";
import {useLayoutEffect, useState} from "react";
import {AppBar, Box, Container, IconButton, Stack, Toolbar, useTheme} from "@mui/material";


import HomeLogo from "./HomeLogo";
import AccountPopover from "./AccountPopover";
import SearchField from "./search/SearchField";
import {MHidden} from "../../../shared/components";
import {useIsAuthPath} from "../../../shared/hooks/useAuth";
import {COLLAPSE_WIDTH, DRAWER_WIDTH} from "../sidebar/HomeSidebar";
import useCollapseDrawer from "../../../shared/hooks/useCollapseDrawer";
import SimpleCircularProgress from "../../../shared/components/SimpleCircularProgress";

// ----------------------------------------------------------------------

export const APPBAR_MOBILE = 64;
export const APPBAR_DESKTOP = 64;

const RootStyle = styled(AppBar)(({theme}) => ({
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
    }
}));

const ToolbarStyle = styled(Toolbar)(({theme}) => ({
    display: "flex",
    // position: "relative",
    // border: "1px solid red",
    // width: "95%",
    // margin: "auto",
    minHeight: APPBAR_MOBILE,
    justifyContent: "space-between",
    [theme.breakpoints.up("lg")]: {
        minHeight: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5)
    }
}));

export function HomeNavbar({onOpenSidebar}) {
    const {isCollapse} = useCollapseDrawer();

    return (
        <RootStyle
            sx={{
                boxShadow: "none",
                backdropFilter: "blur(6px)",
                WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
                backgroundColor: theme => alpha(theme.palette.grey[200], 0.72),
                ...(isCollapse && {
                    width: {lg: `calc(100% - ${COLLAPSE_WIDTH}px)`}
                }),
            }}
        >
            <ToolbarStyle>
                <Stack>
                    <MHidden width="lgUp">
                        <Box display={"flex"} alignItems={"center"}>
                            <IconButton onClick={onOpenSidebar} sx={{mr: 1, color: "text.primary"}}>
                                <Menu/>
                            </IconButton>
                            <HomeLogo/>
                        </Box>
                    </MHidden>

                    <MHidden width="lgDown">
                        <Box width={40}/>
                    </MHidden>
                </Stack>

                <SearchField/>

                <AccountPopover/>

            </ToolbarStyle>
        </RootStyle>
    );
}

export function Navbar() {

    const theme = useTheme();

    const isAuthPath = useIsAuthPath();

    const [ready, setReady] = useState(false);

    useLayoutEffect(() => {
        setReady(true);
    }, [])

    if (isAuthPath) return null;
    if (!ready) return <SimpleCircularProgress/>;

    return (
        <AppBar sx={{
            width: "100%",
            backgroundColor: "white",
        }}>
            <ToolbarStyle>
                <Container maxWidth={"xxl"}>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <HomeLogo/>
                        <AccountPopover/>
                    </Stack>
                </Container>

            </ToolbarStyle>
        </AppBar>
    );
}

