import {useEffect} from "react";
import PropTypes from "prop-types";
import {useRouter} from "next/router";
import SideBarContent from "./SideBarContent";
import {MHidden} from "../../../shared/components";
import {Box, Drawer, alpha, useTheme} from "@mui/material";
import useCollapseDrawer from "../../../shared/hooks/useCollapseDrawer";

// ----------------------------------------------------------------------

export const DRAWER_WIDTH = 330;
export const COLLAPSE_WIDTH = 102;

// ----------------------------------------------------------------------

HomeSidebar.propTypes = {
    isOpenSidebar: PropTypes.bool,
    onCloseSidebar: PropTypes.func
};

export default function HomeSidebar({isOpenSidebar, onCloseSidebar}) {
    const theme = useTheme();
    const {pathname} = useRouter();

    const {
        isCollapse, collapseClick, collapseHover, onHoverEnter, onHoverLeave
    } = useCollapseDrawer();

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
    }, [pathname]);

    return (
        <Box sx={{
            [theme.breakpoints.up("lg")]: {
                flexShrink: 0,
                transition: theme => theme.transitions.create("width", {
                    duration: theme.transitions.duration.complex
                })
            },
            width: {
                lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
            },
            ...(collapseClick && {
                position: "absolute"
            })
        }}>
            <MHidden width='lgUp'>
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{
                        sx: {
                            width: DRAWER_WIDTH,
                            backgroundColor: theme => theme.palette.grey[200],
                        }
                    }}
                >
                    <SideBarContent/>
                </Drawer>
            </MHidden>

            <MHidden width={"lgDown"}>
                <Drawer
                    open
                    variant={"persistent"}
                    onMouseEnter={onHoverEnter}
                    onMouseLeave={onHoverLeave}
                    PaperProps={{
                        sx: {
                            width: DRAWER_WIDTH,
                            backgroundColor: theme => theme.palette.grey[200],
                            ...(isCollapse && {
                                width: COLLAPSE_WIDTH
                            }),
                            ...(collapseHover && {
                                borderRight: 0,
                                backdropFilter: "blur(6px)",
                                WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
                                boxShadow: theme => theme.customShadows.z20,
                                backgroundColor: theme => alpha(theme.palette.grey[200], 0.88)
                            })
                        }
                    }}
                >
                    <SideBarContent/>
                </Drawer>
            </MHidden>
        </Box>
    );
}


