import {useRouter} from "next/router";
import {useCallback, useEffect, useMemo, useState} from "react";

import {AUTH_PATH} from "../../features/auth/paths";
import {BACKOFFICE_PATH} from "../../features/paths";

export const useRoutesMatcher = () => {
    const {pathname, ...others} = useRouter();

    const routeMatch = useCallback(({path, exact}) => {
        if (!path) return false;

        if (exact) return path === pathname;

        return pathname.indexOf(path) >= 0;
    }, [pathname]);

    return {...others, routeMatch, pathname};
}

export const usePathNames = () => {
    const {routeMatch, ...others} = useRoutesMatcher();

    const {isAuth, isBackoffice, isClient} = useMemo(() => {
        const isAuth = routeMatch({path: AUTH_PATH.root});
        const isBackoffice = routeMatch({path: BACKOFFICE_PATH.root});

        const isClient = !isAuth && !isBackoffice;

        return {isAuth, isBackoffice, isClient}
    }, [routeMatch]);

    return {...others, isAuth, isBackoffice, isClient, routeMatch};
}

export const useRouteChange = () => {
    const router = useRouter();
    const [url, setUrl] = useState();
    const [shallow, setShallow] = useState();
    const [pageLoading, setPageLoading] = useState(false);

    useEffect(() => {
        const routeChange = pageLoading => (url, {shallow}) => {
            setUrl(url);
            setShallow(shallow);
            setPageLoading(pageLoading);
        }

        const routeChangeStart = routeChange(true);
        const routeChangeComplete = routeChange(false);

        router.events.on("routeChangeStart", routeChangeStart);
        router.events.on("routeChangeError", routeChangeComplete);
        router.events.on("routeChangeComplete", routeChangeComplete);

        return () => {
            router.events.off("routeChangeStart", routeChangeStart);
            router.events.off("routeChangeError", routeChangeComplete);
            router.events.off("routeChangeComplete", routeChangeComplete);
        }

    }, [router]);

    return {pageLoading, shallow, url, router};
}
