import {AccountCircle} from "@mui/icons-material";
import {Avatar, Box, CardActionArea, Divider, Link, Stack, Tooltip, Typography} from "@mui/material";

import NavSection from "./NavSection";
import {BACKOFFICE_PATH} from "../../paths";
import useAuth from "../../../shared/hooks/useAuth";
import {MHidden} from "../../../shared/components";
import {LogoAvatar} from "../../../shared/components/Logo";
import SimpleLink from "../../../shared/components/SimpleLink";
import useCollapseDrawer from "../../../shared/hooks/useCollapseDrawer";
import {concatUrl} from "../../../utils/http.utils";
// ----------------------------------------------------------------------

export default function SideBarContent() {
    return (
        <Stack height={"100vh"}>

            <SideHeader/>

            <NavSection/>

            <Divider/>

            <SideFooter/>

        </Stack>
    )
}

function SideHeader() {

    const {user} = useAuth();
    const {isCollapse, collapseClick, onToggleCollapse} = useCollapseDrawer();

    return (
        <Stack
            spacing={3}
            sx={{
                px: 2.5, pt: 3, pb: 2,
                ...(isCollapse && {
                    alignItems: "center"
                })
            }}
        >
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <SimpleLink href={'/'}>
                    <Box sx={{display: 'inline-flex'}}>
                        <LogoAvatar width={60} height={60}/>
                    </Box>
                </SimpleLink>

                <MHidden width="lgDown">
                    {!isCollapse && (
                        <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick}/>
                    )}
                </MHidden>
            </Stack>

            {/*<UserInfo user={user} isCollapse={isCollapse}/>*/}

        </Stack>
    )
}

function IconCollapse({onToggleCollapse, collapseClick}) {
    return (
        <Tooltip title="Mini Menu">
            <CardActionArea
                onClick={onToggleCollapse}
                sx={{
                    width: 18,
                    height: 18,
                    display: 'flex',
                    cursor: 'pointer',
                    borderRadius: '50%',
                    alignItems: 'center',
                    color: 'text.primary',
                    justifyContent: 'center',
                    border: 'solid 1px currentColor',
                    ...(collapseClick && {
                        borderWidth: 2
                    })
                }}
            >
                <Box sx={{
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    bgcolor: 'currentColor',
                    transition: (theme) => theme.transitions.create('all'),
                    ...(collapseClick && {
                        width: 0,
                        height: 0
                    })
                }}/>
            </CardActionArea>
        </Tooltip>
    );
}

function UserInfo({isCollapse, user}) {

    if(!user) return <></>;

    if (isCollapse) return (
        <Avatar>
            <AccountCircle/>
        </Avatar>
    )

    return (
        <SimpleLink href={concatUrl(BACKOFFICE_PATH.users.root, user.uid, "profil")}>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                padding: theme => theme.spacing(2, 2.5),
                borderRadius: theme => theme.shape.borderRadiusSm,
                backgroundColor: theme => theme.palette.grey[300]
            }}>
                {user.photoURL && (
                    <Avatar src={user.photoURL} alt="photoURL"/>
                )}

                {!user.photoURL && (
                    <Avatar>
                        <AccountCircle/>
                    </Avatar>
                )}

                <Box sx={{ml: 2}}>
                    <Typography variant="subtitle2" sx={{color: "text.primary"}}>
                        {user?.name ?? user.email ?? user.phone ?? "Bci"}
                    </Typography>
                </Box>
            </Box>
        </SimpleLink>
    );
}

function SideFooter() {
    return (
        <Box sx={{
            bottom: 0,
            position: "sticky",
            textAlign: "center",
            paddingTop: 2,
            paddingBottom: 1,
            backgroundColor: theme => theme.palette.grey[200],
        }}>
            <Typography gutterBottom variant={"body2"} color={"text.secondary"}>
                {"Réalisé par"}
            </Typography>

            <Link underline={"hover"} href={"https://loga-engineering.com"} target={"_blank"}>
                <Typography gutterBottom variant='subtitle1'>
                    {"Log@ Engineering"}
                </Typography>
            </Link>
        </Box>
    )
}
