import {motion} from 'framer-motion';
// @mui
import {styled} from '@mui/material/styles';
import {Button, Typography, Container, Box} from '@mui/material';
// components
import Page from './Page';
import {MotionContainer} from '../components/animate';
import {varBounce} from '../components/animate/variants2';
// assets
import ForbiddenIllustration from '../../assets/illustration_403';
import NextLink from "next/link";

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({theme}) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Page403() {
    return (
        <Page title="403 Forbidden">
            <Container>
                <MotionContainer initial="initial" open>
                    <ContentStyle sx={{textAlign: 'center', alignItems: 'center'}}>
                        <motion.div variants={varBounce().in}>
                            <Typography variant="h3" paragraph>
                                {"Aucune autorisation"}
                            </Typography>
                        </motion.div>

                        <motion.div variants={varBounce().in}>
                            <Typography sx={{color: 'text.secondary'}}>
                                {"La page à laquelle vous essayez d'accéder a un accès restreint."}
                                <br/>
                                {"Veuillez consulter votre administrateur système"}
                            </Typography>
                        </motion.div>

                        <motion.div variants={varBounce().in}>
                            <ForbiddenIllustration sx={{height: 260, my: {xs: 5, sm: 10}}}/>
                        </motion.div>

                        <NextLink href={"/"} passHref>
                            <Button size="large" variant="contained">
                                {"Page d'accueil"}
                            </Button>
                        </NextLink>

                    </ContentStyle>
                </MotionContainer>
            </Container>
        </Page>
    );
}
