import {useRouter} from "next/router";
import {useMemo, useRef} from "react";
import {alpha, Avatar, Box, Button, Divider, IconButton, MenuItem, Typography} from "@mui/material";
import {
    AccountCircle,
    ContactMail,
    CurrencyFranc,
    Home,
    Money,
    Receipt,
    Scanner,
    SettingsSuggestRounded
} from "@mui/icons-material";

import {MenuPopover} from "../../../shared/components";
import {BACKOFFICE_PATH, SELF_CARE_PATH} from "../../paths";
import {useMessage} from "../../../shared/hooks/useMessage";
import {EventRoles} from "../../backoffice/general/events/event.roles";
import {BackofficeRoles} from "../../backoffice/backoffice.roles";
import {TicketRoles} from "../../backoffice/general/tickets/ticket.roles";
import useIsMountedRef from "../../../shared/hooks/useIsMountedRef";
import {useUserAuthorities} from "../../backoffice/admin/users/user.service";
import SimpleLink, {ButtonLink} from "../../../shared/components/SimpleLink";
import {useOpen} from "../../../shared/hooks/hooks.utils";

export default function AccountPopover() {
    const {push, pathname} = useRouter();

    const {user, menus, logout, isAnonymous, isInitialise} = useUserMenus();

    const {showError} = useMessage();
    const isMountedRef = useIsMountedRef();
    const anchorRef = useRef(null);

    const {open, onClose, onOpen} = useOpen();

    if (!isInitialise) return <></>;

    const {photoURL} = user ?? {};

    const handleLogout = async () => {
        try {
            if (isMountedRef.current) {
                onClose();
            }
            await logout();
            if (pathname?.includes(BACKOFFICE_PATH.root)) push(SELF_CARE_PATH.root).then();
        } catch (error) {
            console.error(error);
            showError("Impossible de se connecter");
        }
    };

    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={onOpen}
                sx={{
                    padding: 0,
                    width: 44,
                    height: 44,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            backgroundColor: theme => alpha(theme.palette.grey[900], 0.72)
                        }
                    })
                }}
            >
                {!photoURL && (
                    <Avatar>
                        <AccountCircle/>
                    </Avatar>
                )}
                {photoURL && (
                    <Avatar src={photoURL} alt="photoURL"/>
                )}
            </IconButton>

            <MenuPopover
                open={open}
                onClose={onClose}
                anchorEl={anchorRef.current}
                sx={{width: 280}}
            >
                {!isAnonymous && (
                    <>
                        <Box sx={{my: 1.5, px: 2.5}}>
                            <Typography variant="subtitle1" noWrap>
                                {user?.displayName}
                            </Typography>
                            <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
                                {user?.email}
                            </Typography>
                            <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
                                {user?.phoneNumber}
                            </Typography>

                        </Box>
                        <Divider sx={{my: 1}}/>
                    </>
                )}


                {menus.map(({icon: Icon, label, iconColor, linkTo}) => (
                    <SimpleLink key={label} href={linkTo}>
                        <MenuItem
                            key={label}
                            onClick={onClose}
                            sx={{typography: "body2", py: 1, px: 2.5, color: "black"}}
                        >
                            <Box
                                icon={Icon}
                                component={Icon}
                                color={iconColor}
                                sx={{mr: 2, width: 24, height: 24}}
                            />
                            {label}
                        </MenuItem>
                    </SimpleLink>
                ))}

                <Divider sx={{my: 1}}/>

                <Box sx={{p: 2, pt: 1.5}}>
                    {!isAnonymous && (
                        <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
                            {"Se deconnecter"}
                        </Button>
                    )}
                    {isAnonymous && (
                        <ButtonLink href={"/auth/login"} buttonProps={{
                            fullWidth: true, color: "inherit", variant: "outlined", sx: {textTransform: "none"}
                        }}>
                            {"Se connecter"}
                        </ButtonLink>
                    )}
                </Box>

            </MenuPopover>
        </>
    );
}

// ----------------------------------------------------------------------
const iconColor = "#373738";
const roleAllUser = "allUser";

const MENU_OPTIONS = [
    {
        iconColor,
        icon: Home,
        label: "Accueil",
        linkTo: '/',
        role: roleAllUser
    },
    {
        iconColor,
        label: "Backoffice",
        icon: SettingsSuggestRounded,
        linkTo: BACKOFFICE_PATH.home,
        role: BackofficeRoles.ACCESS.name,
    },
    {
        iconColor,
        icon: Money,
        label: "Vente de ticket",
        linkTo: BACKOFFICE_PATH.events.sale.list,
        role: EventRoles.TICKET_SALE.name,
    },
    {
        iconColor,
        icon: Scanner,
        label: "Scanner un ticket",
        linkTo: BACKOFFICE_PATH.scanQRCode,
        role: TicketRoles.TICKET_VALIDATE.name,
    },
    {
        iconColor,
        icon: CurrencyFranc,
        label: "Mes paiements",
        linkTo: SELF_CARE_PATH.myPayments.list,
        role: roleAllUser
    },
    {
        iconColor,
        icon: Receipt,
        label: "Mes tickets",
        linkTo: SELF_CARE_PATH.myTickets.list,
        role: roleAllUser
    },
    {
        iconColor,
        icon: ContactMail,
        label: "Nous contacter",
        linkTo: SELF_CARE_PATH.contactUsMessageInfos,
        role: roleAllUser
    },
];
// ----------------------------------------------------------------------

export const useUserMenus = () => {
    const {user, roles, ...others} = useUserAuthorities();


    const menus = useMemo(() => {
        return MENU_OPTIONS.filter(({role}) => {
            return role === roleAllUser || roles?.some(({name}) => name === role);
        });
    }, [roles]);

    return {user, roles, menus, ...others};
}

