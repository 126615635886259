import {RecoilRoot} from "recoil";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";


// editor
import "../styles/globals.css";
import "react-quill/dist/quill.snow.css";

import App from "../@client/features/@home/App";
import AuthProvider from "../@client/shared/contexts/AuthContext";
import {SettingsProvider} from "../@client/shared/contexts/SettingsContext";
import {ReactQueryClientProvider} from "../@client/shared/contexts/ReactQueryContext";
import {CollapseDrawerProvider} from "../@client/shared/contexts/CollapseDrawerContext";
import GMapsLoadScriptProvider from "../@client/shared/contexts/GMapsLoadScriptContext";
import "../styles/ticket.css";

export default function MyApp({Component, pageProps}) {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <RecoilRoot>
                <SettingsProvider>
                    <CollapseDrawerProvider>
                        <ReactQueryClientProvider>
                            <AuthProvider>
                                <GMapsLoadScriptProvider>
                                    <App>
                                        <Component {...pageProps} />
                                    </App>
                                </GMapsLoadScriptProvider>
                            </AuthProvider>
                        </ReactQueryClientProvider>
                    </CollapseDrawerProvider>
                </SettingsProvider>
            </RecoilRoot>
        </LocalizationProvider>
    )
}
