import {concatUrl} from "../utils/http.utils";

const BACKOFFICE = "/backoffice";

const ADMIN = concatUrl(BACKOFFICE, "admin");
const GENERAL = concatUrl(BACKOFFICE, "general");
const CONFIGURATION = concatUrl(BACKOFFICE, "configuration");

// gen.
const CONTACT_US_MESSAGE_ROOT = concatUrl(GENERAL, "contact-us-messages");
const EVENT_ROOT = concatUrl(GENERAL, "events");
const PAYMENT_ROOT = concatUrl(GENERAL, "payments");
const TICKETS_ROOT = concatUrl(GENERAL, "tickets");
const REPORTING_ROOT = concatUrl(GENERAL, "reporting");


// admin
const USER_ROOT = concatUrl(ADMIN, "users");
const GROUP_ROOT = concatUrl(ADMIN, "groups");
const USER_LOGS_ROOT = concatUrl(ADMIN, "user-logs");

// configuration
const HOME_PAGE_ROOT = concatUrl(CONFIGURATION, "general-config");
const DEPARTURE_ROOT = concatUrl(CONFIGURATION, "departures");
const PRESENTATION_DATA_ROOT = concatUrl(CONFIGURATION, "presentation");
const CONTACT_US_MESSAGE_INFOS = concatUrl(CONFIGURATION, "contact-us-message-infos");

export const BACKOFFICE_PATH = {

    root: BACKOFFICE,
    admin: ADMIN,
    general: GENERAL,
    configuration: CONFIGURATION,
    home: concatUrl(GENERAL, "home"),

    scanQRCode: concatUrl(GENERAL, "scan-qrcode"),

    homePage: {
        display: HOME_PAGE_ROOT,
        edit: concatUrl(HOME_PAGE_ROOT, "edit"),
    },

    reporting: REPORTING_ROOT,

    events: {
        list: EVENT_ROOT,
        root: EVENT_ROOT,
        new: concatUrl(EVENT_ROOT, "new"),
        duplicate: (id, colName) => concatUrl(EVENT_ROOT, id, `duplicate?colName=${colName}`),
        scanQRCode: concatUrl(EVENT_ROOT, "scan-qrcode"),

        pending: {
            list: EVENT_ROOT,
            display: (id) => concatUrl(EVENT_ROOT, "pending", id),
            edit: (id) => concatUrl(EVENT_ROOT, "pending", id, "edit"),
            partialEdit: (id) => concatUrl(EVENT_ROOT, "validated", id, "edit"),
        },
        validated: {
            list: `${EVENT_ROOT}?tab=validated`,
            display: (id) => concatUrl(EVENT_ROOT, "validated", id),
        },
        sale: {
            list: concatUrl(EVENT_ROOT, "sale"),
            display: (id) => concatUrl(EVENT_ROOT, "sale", id),
        }
    },

    presentation: {
        display: PRESENTATION_DATA_ROOT,
        edit: concatUrl(PRESENTATION_DATA_ROOT, "edit"),
    },

    contactUsMessageInfos: {
        display: CONTACT_US_MESSAGE_INFOS,
        edit: concatUrl(CONTACT_US_MESSAGE_INFOS, "edit"),
    },

    users: {
        list: USER_ROOT,
        root: USER_ROOT,
        new: concatUrl(USER_ROOT, "new"),
        display: (id) => concatUrl(USER_ROOT, id),
        edit: (id) => concatUrl(USER_ROOT, id, "edit"),
    },

    departures: {
        list: DEPARTURE_ROOT,
        root: DEPARTURE_ROOT,
        new: concatUrl(DEPARTURE_ROOT, "new"),
        display: (id) => concatUrl(DEPARTURE_ROOT, id),
        edit: (id) => concatUrl(DEPARTURE_ROOT, id, "edit"),
    },

    groups: {
        list: GROUP_ROOT,
        root: GROUP_ROOT,
        new: concatUrl(GROUP_ROOT, "new"),
        display: (id) => concatUrl(GROUP_ROOT, id),
        edit: (id) => concatUrl(GROUP_ROOT, id, "edit"),
        assign: (id) => concatUrl(GROUP_ROOT, id, "assign"),
    },

    tickets: {
        list: TICKETS_ROOT,
        root: TICKETS_ROOT,
        new: concatUrl(TICKETS_ROOT, "new"),
        display: (id) => concatUrl(TICKETS_ROOT, id),
        edit: (id) => concatUrl(TICKETS_ROOT, id, "edit"),
    },

    payments: {
        list: PAYMENT_ROOT,
        root: PAYMENT_ROOT,
        new: concatUrl(PAYMENT_ROOT, "new"),
        display: (id) => concatUrl(PAYMENT_ROOT, id),
        edit: (id) => concatUrl(PAYMENT_ROOT, id, "edit"),
    },
    messages: {
        list: CONTACT_US_MESSAGE_ROOT,
        root: CONTACT_US_MESSAGE_ROOT,
        display: (id) => concatUrl(CONTACT_US_MESSAGE_ROOT, id),
    },
    userLogs: {
        list: USER_LOGS_ROOT,
        root: USER_LOGS_ROOT,
        display: (id) => concatUrl(USER_LOGS_ROOT, id),
    },
}


const myPaymentPath = "/my-payments";
const myTicketPath = "/my-tickets";
const eventPath = "/events";
const contactUsPath = "/contact-us";

export const SELF_CARE_PATH = {
    root: "",
    home: "/",
    scanQRCode: "/scan-qrcode",
    events: {
        list: eventPath,
        root: eventPath,
        new: concatUrl(eventPath, "new"),
        display: (id) => concatUrl(eventPath, id),
        edit: (id) => concatUrl(eventPath, id, "edit"),
        pay: (eventId, offerId) => concatUrl(eventPath, eventId, `payment?offerId=${offerId}`),
    },

    myTickets: {
        list: myTicketPath,
        display: (id) => concatUrl(myTicketPath, id),
    },
    sharedTicket: (id) => `/shared-tickets/${id}`,

    contactUsMessageInfos: contactUsPath,

    myPayments: {
        list: myPaymentPath,
        root: myPaymentPath,
        display: (id) => concatUrl(myPaymentPath, id),
        edit: (id) => concatUrl(myPaymentPath, id, "edit"),
        invoice: (id) => concatUrl(myPaymentPath, id, "invoice"),
    },
}

export const backOfficeItems = [
    {name: "Administration", href: BACKOFFICE},
];
