import moment from 'moment';

export function formErrorMessageParser({error, isEdit}) {
    return (typeof error === 'string') ? error : !isEdit ? 'Erreur lors de la creation' : 'Erreur lors de la modification';
}

export function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

export function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

export function union(a, b) {
    return [...a, ...not(b, a)];
}

export function secondToDate(date) {
    if (!date) return '';
    return moment(new Date(date.toDate())).format('L');
}

export function howManyDays(startingDay, endingDate) {
    const start = moment(startingDay);
    const end = moment(endingDate);
    return moment.duration(end.diff(start)).asDays();
}

export function dateToMMddYYYY(date) {
    return moment(date).format('L');
}
export function formatDate(date, separator, locale) {
    if (!date) {
        date = new Date();
    }
    if (separator === undefined) {
        separator = "/";
    }

    if (locale === undefined) {
        locale = false;
    }
    const format = locale
        ? "YYYY" + separator + "MM" + separator + "DD"
        : "DD" + separator + "MM" + separator + "YYYY";
    return moment(date).format(format);
}

export function addZeroes(num) {
    if (!num) {
        return;
    }
    // return num.toLocaleString('en', {useGrouping: false, minimumFractionDigits: 1});
    return Math.round(num * 100) / 100;
}

export function roundValue(num) {
    if (!num) {
        return;
    }
    return Math.round(num * 100) / 100;
}


export function currency(value, devise) {
    const cur = devise === 'EURO' ? 'EUR' : devise === 'DOLLAR' ? 'USD' : 'XOF';
    return new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: cur
    }).format(+value);
}

export function numberFomatter(value) {
    if (!value) return 0;
    return (value).toLocaleString(undefined, { minimumFractionDigits: 0});
}

export function stopPropagation(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
}

