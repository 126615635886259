import {BACKOFFICE_PATH} from "../../paths";
import {Apps} from "@mui/icons-material";
import {EventRoles} from "./events/event.roles";
import {TicketRoles} from "./tickets/ticket.roles";
import {PaymentRoles} from "./payments/payment.roles";
import {ReportingRoles} from "./reporting/reporting.roles";
import {ContactUsMessageInfoRoles} from "./contact-us-messages/contact.us.message.info.roles";

export const GeneralMenu = [
    {
        title: "Général",
        path: BACKOFFICE_PATH.general,
        icon: <Apps/>,
        children: [
            {
                title: "Accueil",
                path: BACKOFFICE_PATH.home,
                roles: [...EventRoles.names(), ...PaymentRoles.names(), ...ContactUsMessageInfoRoles.names(), ...ReportingRoles.names()]
            },
            {title: "Reporting", path: BACKOFFICE_PATH.reporting, roles: ReportingRoles.names()},
            {title: "Evénements", path: BACKOFFICE_PATH.events.pending.list, roles: EventRoles.names()},
            {title: "Paiements", path: BACKOFFICE_PATH.payments.list, roles: PaymentRoles.names()},
            {title: "Messages", path: BACKOFFICE_PATH.messages.list, roles: ContactUsMessageInfoRoles.names()},
            {title: "Scanner un ticket", path: BACKOFFICE_PATH.scanQRCode, roles: TicketRoles.names()},
        ]
    }
];
