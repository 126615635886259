import {
    getAuth,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signInAnonymously as _signInAnonymously,
    onAuthStateChanged as _onAuthStateChanged,
    GoogleAuthProvider, FacebookAuthProvider,
    GithubAuthProvider, signInWithPopup,
    updateProfile
} from "firebase/auth";

import {app} from "./@firebase";

export const auth = getAuth(app);

export const onAuthStateChanged = (observer) => _onAuthStateChanged(auth, observer);

export const signInAnonymously = async () => {
    try {
        const result = await _signInAnonymously(auth);
        const {user} = result;
        const update = await updateProfile(user, {displayName: "Anonyme"});
        console.log({result, update});
        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export const signUpWithEmailAndPassword = async ({email, password, firstName, lastName}) => {
    try {
        const result = await createUserWithEmailAndPassword(auth, email, password);
        const {user} = result;
        const update = await updateProfile(user, {displayName: `${firstName} ${lastName}`});
        console.log({result, update});
        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export const loginWithEmailAndPassword = ({email, password}) => signInWithEmailAndPassword(auth, email, password);

export const sendResetPasswordEmail = ({email}) => sendPasswordResetEmail(auth, email);

const loginWithProviderPopup = (Provider) => {
    const provider = new Provider();

    return async () => {
        try {
            const result = await signInWithPopup(auth, provider);
            const credential = Provider.credentialFromResult(result);
            const token = credential?.accessToken;
            const user = result.user;
            console.log({credential, token, user});
            return true;
        } catch (error) {
            console.log(error);
            const errorCode = error.code;
            const errorMessage = error.message;
            const email = error.email;
            const credential = Provider.credentialFromError(error);
            console.log({errorCode, errorMessage, email, credential});
            return false;
        }
    }
}

export const loginWithGoogle = loginWithProviderPopup(GoogleAuthProvider);

export const loginWithFacebook = loginWithProviderPopup(FacebookAuthProvider);

export const loginWithGithub = loginWithProviderPopup(GithubAuthProvider);

export const logout = async () => {
    try {
        await auth.signOut();
        console.log("logout");
    } catch (e) {
        console.log(e)
    }
};

