import {BACKOFFICE_PATH} from "../../paths";
import {UserRoles} from "./users/user.roles";
import {PeopleAlt} from "@mui/icons-material";
import {UserLogsRoles} from "./user-logs/user.logs.roles";
import {UserGroupRoles} from "./user-groups/user.group.roles";
import {concatUrl} from "../../../utils/http.utils";

export const AdminMenu = [
    {
        title: "Administration",
        path: BACKOFFICE_PATH.admin,
        icon: <PeopleAlt/>,
        children: [
            { title: "Accueil", path: concatUrl(BACKOFFICE_PATH.admin, "home"), roles: [...UserGroupRoles.names(),...UserRoles.names()]},
            { title: "Groupe d'utilisateurs", path: BACKOFFICE_PATH.groups.list,  roles: UserGroupRoles.names() },
            { title: "Utilisateurs", path: BACKOFFICE_PATH.users.list , roles: UserRoles.names()},
            { title: "Logs", path: BACKOFFICE_PATH.userLogs.list , roles: UserLogsRoles.names()},
        ]
    }
];
