import {Box} from "@mui/material";

import SearchModuleItem from "./SearchModuleItem";
import SimpleLink from "../../../../shared/components/SimpleLink";


export default function SearchModule({module, onSelectItem}) {
    const {title, path, icon, children} = module;
    return (
        <Box mb={1}>
            <Box display={"flex"} alignItems={"center"}>
                {icon}
                <SearchLink title={title} path={path} onClick={() => onSelectItem(title)}/>
            </Box>

            {children?.map((item, index) => (
                <SearchModuleItem key={index} item={item} onSelectItem={onSelectItem}/>
            ))}
        </Box>
    );
}


export function SearchLink({title, path, fontWeight = 500, onClick}) {
    if(!path) return null;
    return (
        <SimpleLink href={path} underline={"hover"} muiLinkProps={{
            onClick,
            sx: {
                fontWeight,
                marginLeft: 1,
                textTransform: 'capitalize',
            }
        }}>
            {title}
        </SimpleLink>
    );
}
