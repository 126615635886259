import {useEffect} from "react";
import {atomFamily, useRecoilState} from "recoil";
import {useQuery, useQueryClient} from "react-query";
import {getDoc, limit, orderBy, query as _query, startAfter, where} from "firebase/firestore";

import {documentRef, execQueryForList} from "./db.firebase";

const firebaseFetchState = atomFamily({
    key: "FIREBASE_FETCH_STATE",
    default: null,
})

export const useFirebaseFetchState = (key) => {
    return useRecoilState(firebaseFetchState(key));
}

export const initialSearchQueryData = {
    page: 0,
    size: 20,
    data: [],
    query: "",
    lastPageCount: 0,
}

export const useSearchDocs = (
    {colRef, state, queryRootName = "root", queryName, searchField = "keys"}
) => {
    const [queryData, setQueryData] = useRecoilState(state);

    const queryKey = [queryRootName, queryName, queryData.query, queryData.page, queryData.size];
    const queryFn = async () => {
        const {query, size} = queryData;
        let fetchQuery = colRef;
        if (query && query !== "%") {
            fetchQuery = _query(fetchQuery, where(searchField, "array-contains", query));
        }
        fetchQuery = _query(fetchQuery, limit(size));

        const _data = await execQueryForList(fetchQuery);
        return _data;
    }

    const {data, refetch, ...others} = useQuery(queryKey, queryFn, {});

    useEffect(() => {
        if (!data) return;
        setQueryData(queryData => ({...queryData, data}));
    }, [data]);


    const setQuery = (query) => {
        setQueryData(queryData => ({...queryData, query}));
    }
    const removeById = (id) => {
        const data = queryData.data.filter(({id: _id}) => id !== _id);
        setQueryData(queryData => ({...queryData, data}));
    }

    return {...queryData, ...others, setQuery, removeById}
}

export const useSearchDocsInfinity = ({colRef, state, queryRootName = "root", queryName, searchField = "keys"}) => {
    const [queryData, setQueryData] = useRecoilState(state);

    const queryKey = [queryRootName, queryName, queryData.query, queryData.page, queryData.size];
    const queryFn = async () => {
        const {query, size, data} = queryData;
        let fetchQuery = colRef;
        if (query && query !== "%") {
            fetchQuery = _query(fetchQuery, where(searchField, "array-contains", query));
        }
        if (data.length) {
            const lastDoc = data[data.length - 1];
            const docSnap = await getDoc(documentRef(lastDoc.ref));
            fetchQuery = _query(fetchQuery, startAfter(docSnap));
        }
        fetchQuery = _query(fetchQuery, limit(size));

        const _data = await execQueryForList(fetchQuery);
        return _data;
    }

    const {data: lastPage, refetch: _refetch, ...others} = useQuery(queryKey, queryFn, {});

    const refetch = async () => {
        setQueryData(queryData => ({
            ...queryData, data: [], lastPageCount: 0, page: 0
        }));

        return _refetch();
    }

    useEffect(() => {
        if (!lastPage) return;

        const ids = queryData.data.map(({id}) => id);
        const _lastPage = lastPage.filter(({id}) => ids.indexOf(id) === -1);

        if (!_lastPage.length) {
            setQueryData(queryData => ({...queryData, lastPageCount: 0}));
            return;
        }

        setQueryData(queryData => ({
            ...queryData, lastPageCount: lastPage.length, data: [...queryData.data, ..._lastPage]
        }));

    }, [lastPage]);


    const setQuery = (query) => {
        setQueryData(queryData => ({
            ...queryData, query, data: [], lastPageCount: 0, page: 0
        }));
    }

    const hasMore = queryData.lastPageCount === queryData.size;
    const fetchMore = () => setQueryData(queryData => ({...queryData, page: queryData.page + 1}));
    const removeById = (id) => {
        const data = queryData.data.filter(({id: _id}) => id !== _id);
        setQueryData(queryData => ({...queryData, data}));
    }

    return {
        ...queryData, ...others, refetch, setQuery, hasMore, fetchMore, removeById
    }
}



export const useInvalidateQueries = () => {
    const queryClient = useQueryClient();
    return (filters) => queryClient.invalidateQueries(filters).then();
}

export const useInvalidateQueriesByRootName = (rootName) => {
    const invalidateQueries = useInvalidateQueries();
    return () => invalidateQueries(rootName).then();
}
