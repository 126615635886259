import {CommonDoc} from "../../../../shared/services/firebase/domain.firebase";

export const User = {
    colName: 'users',

    ...CommonDoc,

    displayName: 'address', // string
    email: 'email', // string
    phoneNumber: 'phoneNumber', // string
    password: 'password', // string
    assignPassword: 'assignPassword', // string
}
export const UserGroup = {
    colName: 'user_groups',

    ...CommonDoc
}

export const UserGroupRole = {
    colName: 'user_group_roles',

    ...CommonDoc,

    name: "name", // string
    label: "label", // string
    groupId: "groupId", // string
}
