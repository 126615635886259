import {useQuery} from "react-query";

import {ko, ok} from "../../../../utils/service.utils";
import {useEntityCount} from "../../../../shared/hooks/hooks.utils";
import {crudService} from "../../../../shared/services/firebase/db.firebase";

const colName = 'contact_us_message_infos';
const id = 'contact-us-message-info';
export const {colRef, docRef, create, update, remove, findById, findAll, count} = crudService(colName);

export const saveContactUsMessageInfo = async (entity) => {
    try {
        await update(id, entity);
        return ok(entity, "Les informations de nos contactes modifiées avec succès");
    } catch (e) {
        return ko("Erreur lors de la mise a jour du l'info Nous contacter");
    }
}

export const useFindById = () => {
    const queryKey = ["FIND_CONTACT_US_MESSAGE_INFO_BY_ID", id];
    const queryFn = () => {
        if (!id) return null;
        return findById(id);
    }
    return useQuery(queryKey, queryFn, {});
}

export const useContactUsMessageInfoCount = () => useEntityCount("CONTACT_US_MESSAGE_INFO", count);
