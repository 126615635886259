import {useSnackbar} from "notistack";
import {useEffect, useCallback} from "react";
import {messageFormError} from "../../utils/utils";

export const useMessage = () => {
    const {enqueueSnackbar} = useSnackbar();

    const _message = variant => message => enqueueSnackbar(message, {variant});

    const showSuccess = useCallback(_message("success"), []);

    const showInfo = useCallback(_message("info"), []);

    const showWarning = useCallback(_message("warning"), []);

    const showError = useCallback(_message("error"), []);

    const showErrorObject = useCallback((error, defaultMessage) => {
        _message("error")(messageFormError(error, defaultMessage));
    }, []);

    return {
        showInfo,
        showError,
        showSuccess,
        showWarning,
        showErrorObject,
    }
}

export const useShowErrorMessage = (isError, errorMessage) => {
    const {showError} = useMessage();
    useShowErrorMessage2(isError, errorMessage, showError);
}

export const useShowErrorMessage2 = (isError, errorMessage, showError) => {
    useEffect(() => {
        if (isError && errorMessage) showError("Erreur : " + errorMessage);
    }, [isError, errorMessage]);
}
