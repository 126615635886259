import {AdminMenu} from "../../backoffice/admin/admin.path";
import {GeneralMenu} from "../../backoffice/general/general.path";
import {ConfigurationMenu} from "../../backoffice/configuration/configuration.path";

const sidebarConfig = [
    {
        subheader: "modules",
        items: [
            ...GeneralMenu,
            ...AdminMenu,
            ...ConfigurationMenu,
            // {
            //     title: 'Tests',
            //     path: '/tests',
            //     icon: <Speed/>,
            //     exact: true,
            // },
        ],
    },
];

export default sidebarConfig;