// import { format, formatDistanceToNow } from 'date-fns';
// import moment from 'moment';
//
// // ----------------------------------------------------------------------
//
// export function fDate(date) {
//   return format(new Date(date), 'dd MMMM yyyy');
// }
//
// export function fDateTime(date) {
//   return format(new Date(date), 'dd MMM yyyy HH:mm');
// }
//
// export function fDateTimeSuffix(date) {
//   return format(new Date(date), 'dd/MM/yyyy hh:mm p');
// }
//
// export function fToNow(date) {
//   return formatDistanceToNow(new Date(date), {
//     addSuffix: true
//   });
// }
//
// const _formatYYYYMMDDHHmmss = 'yyyy-MM-dd HH:mm:ss';
// export const dateToYYYYMMDDHHmmss = (date) => _format(_formatYYYYMMDDHHmmss)(date);
//
// const _formatYYYYMMDD = 'yyyy-MM-dd';
// export const dateToYYYYMMDD = (date) => _format(_formatYYYYMMDD)(date);
//
// const _formatHHmmss = 'yyyy-MM-dd HH:mm:ss';
// export const dateToHHmmss = (date) => _format(_formatHHmmss)(date);
//
// const _format = f => date => {
//   if(!date) return '';
//   return format(date, f);
// }
//
// export function formatDate(date, pattern) {
//   if (!date) {
//     date = new Date();
//   }
//   if (!pattern) {
//     pattern = "lll";
//   }
//   moment.locale("fr");
//   return moment(date).format(pattern);
// }


import moment from "moment";
import {format, formatDistanceToNow} from "date-fns";

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), "dd MMMM yyyy");
}

export function fDateTime(date) {
  return format(new Date(date), "dd MMM yyyy HH:mm");
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), "dd/MM/yyyy hh:mm p");
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

const _formatDDMMYYYY = "dd-MM-yyyy";
export const dateToDDMMYYYY = (date) => _format(_formatDDMMYYYY)(date);

const _formatDDMMYYYYHHmmss = "dd-MM-yyyy HH:mm:ss";
export const dateToDDMMYYYYHHmmss = (date) => _format(_formatDDMMYYYYHHmmss)(date);

const _formatYYYYMMDDHHmmss = "yyyy-MM-dd HH:mm:ss";
export const dateToYYYYMMDDHHmmss = (date) => _format(_formatYYYYMMDDHHmmss)(date);

const _formatYYYYMMDD = "yyyy-MM-dd";
export const dateToYYYYMMDD = (date) => _format(_formatYYYYMMDD)(date);

const _formatHHmmss = "HH:mm:ss";
export const dateToHHmmss = (date) => _format(_formatHHmmss)(date);

const _formatHHmm = "HH:mm";
export const dateToHHmm = (date) => _format(_formatHHmm)(date);

const _formatHH = "HH";
export const dateToHH = (date) => _format(_formatHH)(date);

const _formatYyyyMMddSimple = "yyyyMMdd";
export const dateToYyyyMMddSimple = (date) => _format(_formatYyyyMMddSimple)(date);

export const _format = f => date => {
  if(!date) return "";
  return format(date, f);
}

export function formatDate(date, pattern) {
  if (!date) {
    date = new Date();
  }
  if (!pattern) {
    pattern = "lll";
  }
  moment.locale("fr");
  return moment(date).format(pattern);
}
