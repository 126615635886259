import {Enum} from "../../../../utils/enum.utils";
import {useHasRole} from "../../../../shared/hooks/useAuth";

export const DepartureRoles = Enum({
    DEPARTURE_EDIT: {label: "Editer un lieu de départ"},
    DEPARTURE_ADMIN: {label: "Administrer un lieu de départ"},
    DEPARTURE_VIEW: {label: "Créer un lieu de départ"},
});

export const useDepartureRoles = () => {

    const canAdmin = useHasRole([DepartureRoles.DEPARTURE_ADMIN.name]);

    const canDelete = useHasRole([DepartureRoles.DEPARTURE_ADMIN.name]);

    const canCreate = useHasRole([
        DepartureRoles.DEPARTURE_ADMIN.name,
        DepartureRoles.DEPARTURE_EDIT.name
    ]);

    const canUpdate = useHasRole([
        DepartureRoles.DEPARTURE_ADMIN.name,
        DepartureRoles.DEPARTURE_EDIT.name
    ]);

    const canView = useHasRole([
        DepartureRoles.DEPARTURE_ADMIN.name,
        DepartureRoles.DEPARTURE_EDIT.name,
        DepartureRoles.DEPARTURE_VIEW.name
    ]);

    return {canAdmin, canCreate, canUpdate, canDelete, canView};
}
