import {Box} from "@mui/material";

import {SearchLink} from "./SearchModule";

export default function SearchModuleItem({item, onSelectItem}) {
    const {title, path} = item;

    return (
        <Box ml={3} mb={.25}>
            <SearchLink title={title} path={path} fontWeight={300} onClick={() => onSelectItem(title)}/>
        </Box>
    );
}
