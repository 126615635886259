import {createContext, useCallback, useEffect, useState} from "react";

import {
    loginWithEmailAndPassword,
    loginWithFacebook,
    loginWithGithub,
    loginWithGoogle,
    logout,
    onAuthStateChanged,
    sendResetPasswordEmail,
    signInAnonymously,
    signUpWithEmailAndPassword,
} from '../services/firebase/auth.firebase';
import {findUserAndAuthorities} from "../../features/backoffice/admin/users/user.service";

export const AuthContext = createContext({
    user: null,
    userDB: null,
    isAnonymous: true,
    isInitialise: false,
    isAuthenticated: false,
    logout: () => {
    },
    loginWithGoogle: () => {
    },
    loginWithFacebook: () => {
    },
    loginWithGithub: () => {
    },
    roles: [],
    hasRole: (role) => {
    },
    hasNotRole: (role) => {
    },
});

export default function AuthProvider({children}) {

    const [user, setUser] = useState(null);
    const [userDB, setUserDB] = useState(null);
    const [roles, setRoles] = useState([]);
    const [isInitialise, setIsInitialise] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged((user) => {
            setUser(user);
            setIsAuthenticated(!!user);
            setIsInitialise(true);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (!isInitialise) return;

        if (isAuthenticated) return;

        signInAnonymously().then();
    }, [isInitialise, isAuthenticated]);

    useEffect(() => {
        findUserAndAuthorities(user?.uid).then(({roles, user: userDB}) => {
            setRoles(roles);
            setUserDB(userDB);
        });
    }, [user]);


    const hasRole = useCallback((roleName) => {
        if (!roles) return false;

        const roleNames = roles.map(({name}) => name) ?? [];
        const _roleNames = Array.isArray(roleName) ? roleName : (typeof roles === "string") ? [roleName] : [];

        return _roleNames.some(_roleName => roleNames.indexOf(_roleName) >= 0);
    }, [roles]);

    const hasNotRole = useCallback((roleName) => !hasRole(roleName), [hasRole]);

    return (
        <AuthContext.Provider
            value={{
                user,
                userDB,
                logout,
                roles,
                hasRole,
                hasNotRole,
                isInitialise,
                isAuthenticated,
                loginWithGoogle,
                loginWithFacebook,
                loginWithGithub,
                sendResetPasswordEmail,
                loginWithEmailAndPassword,
                signUpWithEmailAndPassword,
                isAnonymous: user?.isAnonymous ?? true,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

