import {Enum} from "../../../../utils/enum.utils";
import {useHasRole} from "../../../../shared/hooks/useAuth";

export const PaymentRoles = Enum({
    PAYMENT_EDIT: {label: "Editer un paiement"},
    PAYMENT_ADMIN: {label: "Administrer un paiement"},
    PAYMENT_VIEW: {label: "Créer un paiement"},
});

export const usePaymentRoles = () => {

    const canAdmin = useHasRole([PaymentRoles.PAYMENT_ADMIN.name]);

    const canDelete = useHasRole([PaymentRoles.PAYMENT_ADMIN.name]);

    const canCreate = useHasRole([
        PaymentRoles.PAYMENT_ADMIN.name,
        PaymentRoles.PAYMENT_EDIT.name
    ]);

    const canUpdate = useHasRole([
        PaymentRoles.PAYMENT_ADMIN.name,
        PaymentRoles.PAYMENT_EDIT.name
    ]);

    const canView = useHasRole([
        PaymentRoles.PAYMENT_ADMIN.name,
        PaymentRoles.PAYMENT_EDIT.name,
        PaymentRoles.PAYMENT_VIEW.name
    ]);

    return {canAdmin, canCreate, canUpdate, canDelete, canView};
}
