import React from 'react';
import {Box, Container, Divider, Typography} from "@mui/material";
import {useIsAuthPath} from "../../../shared/hooks/useAuth";
import ContactUsSocial from "../../contact-us/components/ContactUsSocial";
import {useFindById} from "../../backoffice/configuration/contact-us-message-infos/contact.us.message.info.service";
import {useRouter} from "next/router";
import {BACKOFFICE_PATH} from "../../paths";


export default function Footer() {

    const {data} = useFindById();

    const isAuthPath = useIsAuthPath();
    if (isAuthPath) return null;

    return (
        <Box sx={{
            minHeight: 400,
            position: "relative",
            background: theme => theme.palette.grey[800],
        }}>
            <Container maxWidth={"xl"} sx={{color: "white", py: 4}}>
                <Typography variant={"h5"}>{"Mali Tourisme - Bamako City Tour"}</Typography>

                <Typography sx={{fontSize: {md: 20, xs: 18}}}>
                    {data?.description}
                </Typography>

                <Divider sx={{my: 3}}/>

                {data && <ContactUsSocial data={data}/>}
            </Container>
        </Box>
    );
}

