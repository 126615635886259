import {Enum} from "../../../../utils/enum.utils";
import {useHasRole} from "../../../../shared/hooks/useAuth";

export const ReportingRoles = Enum({
    REPORTING_ADMIN: {label: "Administrer le reporting"},
});

export const useReportingRoles = () => {

    const canAdmin = useHasRole([ReportingRoles.REPORTING_ADMIN.name]);

    const canView = useHasRole([ReportingRoles.REPORTING_ADMIN.name]);

    return {canAdmin, canView};

}
