import {LinearProgress} from "@mui/material";

import {useRouteChange} from "../hooks/useRoutes";

export default function PageLoadingIndicator() {
    const {pageLoading} = useRouteChange();

    if(!pageLoading) return <></>

    return (
        <LinearProgress sx={{position: "absolute", width: "100vw", zIndex: theme => theme.zIndex.drawer + 1}}/>
    );
}

