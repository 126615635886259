import {useCallback, useEffect, useState} from "react";
import {
    addDoc,
    collection,
    collectionGroup,
    deleteDoc,
    doc,
    getCountFromServer,
    getDoc,
    getDocs,
    getFirestore, onSnapshot,
    query,
    runTransaction as _runTransaction,
    serverTimestamp,
    setDoc,
    updateDoc
} from "firebase/firestore";

import {app} from "./@firebase";
import {useFirebaseFetchState} from "./firebase.cache.store";
import {dateToDDMMYYYYHHmmss, dateToYYYYMMDDHHmmss} from "../../../utils/formatTime";

export const db = getFirestore(app);

export const collectionRef = (path, ...pathSegments) => collection(db, path, ...pathSegments);
export const collectionGroupRef = (path) => collectionGroup(db, path);
export const documentRef = (path, ...pathSegments) => doc(db, path, ...pathSegments);

export const findByRef = async (path) => {
    const snap = await getDoc(documentRef(path));
    return extractData(snap);
};

export const updateDocByPath = async (path, item) => {
    const docRef = doc(db, path);
    return updateDoc(docRef, auditorUpdate(item));
}

export const runTransaction = async (updateFunction) => _runTransaction(db, updateFunction);

export const countFromServer = async (query) => {
    if (!query) return 0;
    const snap = await getCountFromServer(query);
    return snap.data().count;
}

export const crudService = (colName) => {
    const colRef = collection(db, colName);
    const docRef = (id) => doc(db, colName, id);

    const newDocRef = () => doc(colRef);
    const create = async (entity) => addDoc(colRef, auditorCreate(entity));
    const update = (id, entity) => updateDoc(docRef(id), auditorUpdate(entity));
    const set = (id, entity) => setDoc(docRef(id), auditorCreate(entity));
    const findById = async (id) => {
        const snap = await getDoc(docRef(id));
        return extractData(snap);
    };
    const remove = (id) => deleteDoc(docRef(id));
    const findAll = () => execForList(query(colRef));
    const count = () => countFromServer(colRef);

    return {
        colRef, docRef, create, update, set, findById, remove, findAll, newDocRef, count
    }
}

export const service = path => {

    const collectionRef = collection(db, path);
    const docRef = (id) => doc(db, path, id);

    const create = async (doc) => addDoc(collectionRef, auditorCreate(doc));
    const update = async (ref, doc) => updateDoc(ref, auditorUpdate(doc));
    const _setDoc = async (ref, doc) => setDoc(ref, auditorUpdate(doc));
    const findById = (id) => getDoc(docRef(id));
    const findByRef = (ref) => getDoc(ref);
    const destroy = async (ref) => deleteDoc(ref);

    return {
        create, update, destroy, collectionRef, docRef, setDoc: _setDoc, findByRef, findById
    }
}

export function auditorCreate(doc) {
    return {...auditorUpdate(doc), createdDate: serverTimestamp()}
}

export function auditorUpdate(doc) {
    return {...doc, lastModifiedDate: serverTimestamp()}
}


export function useFirestoreQuery({key, queryFn, postLoad, cacheFirst = true}) {
    const [error, setError] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useFirebaseFetchState(key);

    const fetch = useCallback(async () => {
        setIsLoading(true);
        try {
            const data = (queryFn && await queryFn()) || [];

            postLoad && await Promise.all(data.map(postLoad));

            error && setError(null);
            isError && setIsError(false);

            setData(data);
        } catch (e) {
            console.log(e);
            setError(e);
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (cacheFirst && data?.length > 0) return;
        fetch().catch(console.log);
    }, [key, cacheFirst]);

    return {
        error,
        isError,
        isLoading,
        data,
        refetch: fetch,
    }
}

export const listFromCollection = (collectionRef) => {
    try {
        return execForList(query(collectionRef));
    } catch (e) {
        console.error(e);
        throw e;
    }
}

export function extractData(snap) {
    // console.log({ref: snap.ref, refKey: snap.ref.path});
    return snap?.exists() && {...snap.data(), id: snap.id, ref: snap.ref.path}
}

export async function execForList(_query, ...queryConstraints) {
    return execQueryForList(query(_query, ...queryConstraints));
}

export async function execQueryForList(_query) {
    const {docs} = await getDocs(_query);
    return docs.map(extractData);
}

export async function execForDoc(docRef) {
    return extractData(await getDoc(docRef));
}

export const onSnapshotDoc = (docRef, observer) => {
    return onSnapshot(docRef, (freshDoc) => {
        observer && observer(extractData(freshDoc));
    });
}

export function timestampToDDMMYYYYHHmmss(timestamp) {
    if (!timestamp) return '';

    return dateToDDMMYYYYHHmmss(timestamp.toDate());
}

export function timestampToYYYYMMDDHHmmss(timestamp) {
    if (!timestamp) return '';

    return dateToYYYYMMDDHHmmss(timestamp.toDate());
}
