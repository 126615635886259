import {sumBy} from "lodash";

const countSection = (section) => sumBy(section.items, module => (module.children?.length ?? 0) + 1);

const itemsCount = (sidebarConfig) => sumBy(sidebarConfig, countSection);

export function match(source, search) {
    return source.toLowerCase().indexOf(search) >= 0;
}

export function filterSidebarConfig(search, navConfig) {
    search = search.toLowerCase();
    const sidebarConfig = navConfig.map(section => filterSection(section, search)).filter(section => !!section);

    const count = itemsCount(sidebarConfig);

    const totalCount = itemsCount(navConfig);

    return {sidebarConfig, totalCount, count};
}

function filterSection(section, search) {
    const {subheader, items: modules} = section;
    if (match(subheader, search)) return section;

    const _modules = modules?.map(module => filterModule(module, search)).filter(module => !!module) ?? [];

    if (_modules.length === 0) return null;

    return {subheader, items: _modules}
}

function filterModule(module, search) {
    const {title, children: items, ...others} = module;
    if (match(title, search)) return module;

    const _items = items?.map(item => filterModuleItem(item, search)).filter(item => !!item) ?? [];

    if (_items.length === 0) return null;

    return {title, children: _items, ...others}
}

function filterModuleItem(item, search) {
    const {title} = item;
    if (match(title, search)) return item;
    return null;
}

export function scaleObject(objectIn = {}, scaleFactor = 1) {
    const objectOut = {};
    for (const key in objectIn) {
        objectOut[key] = objectIn[key] * scaleFactor;
    }
    return objectOut;
}
