import {forwardRef} from "react";
import PropTypes from "prop-types";
import {IconButton} from "@mui/material";

import {ButtonAnimate} from "../animate";

// ----------------------------------------------------------------------

// eslint-disable-next-line react/display-name
const MIconButton = forwardRef(({ children, ...other }, ref) => (
  <ButtonAnimate>
    <IconButton ref={ref} {...other}>
      {children}
    </IconButton>
  </ButtonAnimate>
));

MIconButton.propTypes = {
  children: PropTypes.node
};

export default MIconButton;
