import Head from "next/head";
import {Fragment, useState} from "react";
import {Box, Container, Stack, styled} from "@mui/material";

import Footer from "./footer/Footer";
import {Page} from "../../shared/pages";
import HomeLogo from "./navbar/HomeLogo";
import HomeSidebar from "./sidebar/HomeSidebar";
import LoadingScreen from "../../shared/components/LoadingScreen";
import useCollapseDrawer from "../../shared/hooks/useCollapseDrawer";
import {usePathNames, useRouteChange} from "../../shared/hooks/useRoutes";
import {APPBAR_DESKTOP, APPBAR_MOBILE, HomeNavbar, Navbar} from "./navbar/HomeNavbar";
import useAuth from "../../shared/hooks/useAuth";


const MainStyle = styled("div")(({theme}) => ({
    flexGrow: 1,
    overflow: "auto",
    minHeight: "100vh",
    paddingTop: APPBAR_MOBILE,
    background: theme.palette.grey[200],
    paddingBottom: theme.spacing(10),
    transition: theme.transitions.create("margin", {
        duration: theme.transitions.duration.complex
    }),
    [theme.breakpoints.up("lg")]: {
        paddingTop: APPBAR_DESKTOP,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}));


export default function AppLayout({children}) {

    const {isBackoffice, isAuth, isClient} = usePathNames();
    const {pageLoading} = useRouteChange();

    return (
        <Stack minHeight={"100%"} overflow={"hidden"}>
            <Head>
                <title>{"Bamako City Tour"}</title>
                <meta name={"description"} content={"Mali Tourisme - Bamako City Tour"}/>
                <link rel="icon" href="/favicon.ico"/>
            </Head>

            {isAuth && (
                <AuthLayout pageLoading={pageLoading}>
                    {children}
                </AuthLayout>
            )}

            {isClient && (
                <ClientLayout pageLoading={pageLoading}>
                    {children}
                </ClientLayout>
            )}

            {isBackoffice && (
                <BackofficeLayout pageLoading={pageLoading}>
                    {children}
                </BackofficeLayout>
            )}
        </Stack>
    );
}

export function AuthLayout({pageLoading, children}) {
    return (
        <Page title={"Bamako City Tour"}>
            <Box
                flexGrow={1} overflow={"hidden"}
                sx={{backgroundColor: theme => theme.palette.grey[200], height: '100vh'}}>
                <Container maxWidth={"sm"} sx={{mt: 10}}>
                    <HomeLogo/>
                    {pageLoading && (<LoadingScreen/>)}
                    {!pageLoading && children}
                </Container>
            </Box>
        </Page>
    )
}

function ClientLayout({pageLoading, children}) {
    return (
        <Fragment>
            <Navbar/>
            <Box
                mt={{
                    xs: 6,
                    md: 7,
                    lg: 8
                }}
                flexGrow={1}
                overflow={"hidden"}
                minHeight={"100vh"}
                sx={{backgroundColor: theme => theme.palette.grey[200]}}
            >

                {pageLoading && (
                    <Stack sx={{height: "100vh"}}>
                        <LoadingScreen/>
                     </Stack>
                )}
                {!pageLoading && children}
            </Box>

            <Footer/>
        </Fragment>
    )
}

function BackofficeLayout({pageLoading, children}) {

    const {isInitialise} = useAuth();
    const {collapseClick} = useCollapseDrawer();
    const [open, setOpen] = useState(false);

    return (
        <Box sx={{
            display: "flex",
            minHeight: "100vh",
            overflow: "hidden"
        }}>
            <HomeNavbar onOpenSidebar={() => setOpen(true)}/>
            <HomeSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)}/>

            <MainStyle
                sx={{
                    ...(collapseClick && {
                        ml: "102px"
                    })
                }}
            >
                {pageLoading && <LoadingScreen/>}

                {!pageLoading && (
                    <Box sx={{pt: 3}} flexGrow={1}>
                        {children}
                    </Box>
                )}
            </MainStyle>

        </Box>
    )
}
