import {default as NextLink} from "next/link";
import Button from "@mui/material/Button";
import MuiLink from "@mui/material/Link";

export default function SimpleLink(
    {href, passHref = true, underline = "none", muiLinkProps = {}, nextLinkProps = {}, children}
) {
    return (
        <SimpleNextLink href={href} passHref={passHref} {...nextLinkProps}>
            <MuiLink underline={underline} {...muiLinkProps}>
                {children}
            </MuiLink>
        </SimpleNextLink>
    );
}

export function ButtonLink({href, passHref = true, buttonProps = {}, nextLinkProps = {}, children}) {
    return (
        <SimpleNextLink href={href} passHref={passHref} {...nextLinkProps}>
            <Button {...buttonProps}>
                {children}
            </Button>
        </SimpleNextLink>
    )
}

export function SimpleNextLink({href, passHref = true, children, ...others}) {
    return (
        <NextLink href={href} passHref={passHref} {...others}>
            {children}
        </NextLink>
    );
}

