import {concatUrl} from "../../utils/http.utils";

const ROOTS_AUTH = "/auth";


export const AUTH_PATH = {
    root: ROOTS_AUTH,
    login: concatUrl(ROOTS_AUTH, "login"),
    register: concatUrl(ROOTS_AUTH, "register"),
    reset: concatUrl(ROOTS_AUTH, "reset"),
}
