import Avatar from "@mui/material/Avatar";
import SimpleLink from "../../../shared/components/SimpleLink";

export default function HomeLogo() {
    return (
        <SimpleLink href={"/"}>
            <Avatar sx={{border: (theme) => `1px solid #693b10`}} src={"/images/tour-circle.jpeg"}/>
        </SimpleLink>
    );
}


