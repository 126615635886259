import {Icon} from "@iconify/react";
import githubFill from "@iconify/icons-eva/github-fill";
import twitterFill from "@iconify/icons-eva/twitter-fill";
import facebookFill from "@iconify/icons-eva/facebook-fill";
import {Email, LocationOn, Phone} from "@mui/icons-material";
import {alpha, Avatar, Box, Stack, Typography} from "@mui/material";


export default function ContactUsSocial({footer, data}) {
    const { address, phone, email, facebookLink, twitterLink, githubLink} = data;
    return (
        <Box sx={{mt: footer ? 3 : 0}}>
            <Typography variant={footer ? 'h6' : 'h4'}>
                {"Nos coordonnées"}
            </Typography>

            <Stack spacing={footer ? 1 : 2} mt={footer ? 1 : 2}>
                <InfoLine
                    footer={footer}
                    Icon={LocationOn}
                    label={address}
                    onClick={() => {
                        window.open("https://www.google.com/maps/place/Loga+Engineering/@12.6206784,-8.0336602,17z/data=!3m1!4b1!4m5!3m4!1s0xe51cddb7121a621:0x28e6cb388c754317!8m2!3d12.6206599!4d-8.0315117", "_blank");
                    }}
                />
                <InfoLine
                    footer={footer}
                    Icon={Phone}
                    label={phone}
                    onClick={() => {
                        window.open(`tel:${phone}`);
                    }}
                />
                <InfoLine
                    footer={footer}
                    Icon={Email}
                    label={email}
                    onClick={() => {
                        window.open(`mailto:${email}`);
                    }}
                />

                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} pt={2} gap={2}>
                    <InfoSocial
                        icon={facebookFill}
                        color={'#1877F2'}
                        onClick={() => {
                            window.open(facebookLink, "_blank");
                        }}
                    />
                    <InfoSocial
                        icon={twitterFill}
                        color={'#1DA1F2'}
                        onClick={() => {
                            window.open(twitterLink, "_blank");
                        }}
                    />
                    <InfoSocial
                        icon={githubFill}
                        color={'#000000'}
                        onClick={() => {
                            window.open(githubLink, "_blank");
                        }}
                    />
                </Box>
            </Stack>
        </Box>
    );
}


function InfoLine({Icon, label, footer, onClick}) {
    return (
        <Box
            onClick={onClick}
            sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                transition: theme => theme.transitions.create('all'),
                ':hover': {
                    transform: footer ? null : 'scale(1.01)',
                    color: theme => footer ? alpha(theme.palette.common.white, 0.65) : theme.palette.primary.main,
                }
            }}
        >
            <Icon sx={{mr: 1}}/>
            <Typography sx={{
                fontSize: {
                    xs: footer ? 14 : 18,
                    md: footer ? 14 : 20,
                },
            }}>
                {label}
            </Typography>
        </Box>
    )
}

function InfoSocial({icon, color, onClick}) {
    return (
        <Avatar sx={{
            cursor: 'pointer',
            transition: theme => theme.transitions.create('all'),
            ':hover': {
                color,
                transform: 'scale(1.1)',
                boxShadow: theme => theme.shadows[5],
            }
        }}>
            <Icon icon={icon} onClick={onClick}/>
        </Avatar>
    )
}
