import Image from "next/image";
import {Avatar} from "@mui/material";

// ----------------------------------------------------------------------

export const logoSrc = "/images/tour-circle.jpeg";

export function LogoAvatar({src= logoSrc, width = 40, height = 40}) {
  return (
      <Avatar src={src} sx={{width, height}}/>
  )
}

export default function Logo({src= logoSrc, width = 40, height = 40}) {
  return <Image width={width} height={height} src={src}/>;
}
