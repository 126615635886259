import {AdminPanelSettings} from "@mui/icons-material";

import {BACKOFFICE_PATH} from "../../paths";
import {HomePageRoles} from "./home-page/home.page.roles";
import {DepartureRoles} from "./departures/departure.roles";
import {ContactUsMessageInfoRoles} from "../general/contact-us-messages/contact.us.message.info.roles";
import {concatUrl} from "../../../utils/http.utils";


export const ConfigurationMenu = [
    {
        title: "Configuration",
        path: BACKOFFICE_PATH.configuration,
        icon: <AdminPanelSettings/>,
        children: [
            {
                title: "Accueil",
                path: concatUrl(BACKOFFICE_PATH.configuration, "home"),
                roles: [...ContactUsMessageInfoRoles.names(), ...DepartureRoles.names(), ...HomePageRoles.names()]
            },
            {title: "Lieux de départ", path: BACKOFFICE_PATH.departures.list, roles: DepartureRoles.names()},
            {
                title: "Contacter nous",
                path: BACKOFFICE_PATH.contactUsMessageInfos.display,
                roles: ContactUsMessageInfoRoles.names()
            },
            {title: "Configuration générale", path: BACKOFFICE_PATH.homePage.display, roles: HomePageRoles.names()},
        ]
    }
];

export const commonItems = [
    {name: "Accueil", href: "/"},
    {name: "Administration", href: BACKOFFICE_PATH.root},
];
