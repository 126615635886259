
const sep = "/";
export const concatUrl = (base = "", ...operations) => {
    const startSep = base.startsWith(sep) ? sep : "";
    const url = [base, ...operations]
        .map(token => (typeof token === "string" ? token : `${token}`).trim())
        .filter(token => !!token)
        .map(token => token.startsWith(sep) ? token.substring(1) : token)
        .map(token => token.endsWith(sep) ? token.substring(0, token.length - 1) : token)
        .join(sep);

    return `${startSep}${url}`;
}

export const urlBuilder = (path) => {
    const _url = (...operation) => concatUrl(path, ...operation);

    return {_url, path};
}

const callHttp = async (httpAsyncFn) => {
    if (!httpAsyncFn) throw 'Aucune opération à executer';

    try {
        return httpAsyncFn();
    } catch (error) {
        console.error("@@@@@@@@@@@ =======> Error: ", error);
        let message;
        if (error.response) {
            message = error.response.data;
        } else if (error.request) {
            message = error.request;
        } else if (error.message) {
            message = error.message;
        } else {
            message = error;
        }
        throw message;
    }
}

const callHttpForData = async (httpAsyncFn) => {
    const {data} = await callHttp(httpAsyncFn);

    return data;
}

const http = (_axios, urlBuilder) => {
    const {get, post, put, delete: _delete} = _axios;
    return {
        http: _axios,
        get: (operation, options) => callHttp(() => {
            // const url = urlBuilder(operation);
            // console.log("@@@@@@@@@@@@@======= Get url: ", {url});
            return get(urlBuilder(operation), options);
        }),
        getForData: (operation, data) => callHttpForData(() => {
            // const url = urlBuilder(operation);
            // console.log("@@@@@@@@@@@@@======= Get For Data url: ", {url});
            return get(urlBuilder(operation), data);
        }),
        post: (operation, data) => callHttp(() => post(urlBuilder(operation), data)),
        postForData: (operation, data) => callHttpForData(() => post(urlBuilder(operation), data)),
        putForData: (operation, data) => callHttpForData(() => put(urlBuilder(operation), data)),
        put: (operation, data) => callHttp(() => put(urlBuilder(operation), data)),
        delete: (operation) => callHttp(() => _delete(urlBuilder(operation))),
        deleteForData: (operation) => callHttpForData(() => _delete(urlBuilder(operation))),
    }
}

const create = (http, endpoint) => (entity) => {
    return callHttpForData(() => http.post(endpoint, entity));
}

const search = (http, endpoint) => (entity) => {
    return callHttpForData(() => http.post(endpoint, entity));
}

const searchCustom = (http, endpoint) => (entity) => {
    return callHttpForData(() => http.get(endpoint, entity));
}

const update = (http, endpoint) => (id, entity) => {
    endpoint = concatUrl(endpoint, id);
    return callHttpForData(() => http.put(endpoint, entity));
}

const destroy = (http, endpoint) => (id) => {
    endpoint = concatUrl(endpoint, id);
    return callHttpForData(() => http.destroy(endpoint));
}

const findById = (http, endpoint) => (id) => {
    endpoint = concatUrl(endpoint, id);
    return callHttpForData(() => http.get(endpoint));
}

const findAll = (http, endpoint) => (page = 0, size = 20) => {
    endpoint = concatUrl(endpoint, `?page=${page}&size=${size}`);
    return callHttpForData(() => http.get(endpoint));
}

const crudPartial = http => {
    return {
        create: endpoint => create(http, endpoint),
        update: endpoint => update(http, endpoint),
        destroy: endpoint => destroy(http, endpoint),
        findById: endpoint => findById(http, endpoint),
        findAll: endpoint => findAll(http, endpoint),
        search: endpoint => search(http, endpoint),
        searchCustom: endpoint => searchCustom(http, endpoint),
    }
}

const crud = crudPartial => endpoint => {
    const {create, update, destroy, findById, findAll} = crudPartial;
    return {
        http,
        endpoint,
        url: (operation) => concatUrl(endpoint, operation),
        create: create(endpoint),
        update: update(endpoint),
        destroy: destroy(endpoint),
        findById: findById(endpoint),
        findAll: findAll(endpoint),
        search: search(endpoint),
        searchCustom: searchCustom(endpoint),
    }
}

const asyncOpDataFn = () => async (operation, data) => {
    console.log("### asyncOpDataFn: ", {operation, data});
}

const asyncOpFn = () => async (operation) => {
    console.log("### asyncOpFn: ", {operation});
}
const asyncDataFn = () => async (data) => {
    console.log("### asyncDataFn: ", {data});
}
const asyncFn = () => async () => {
    console.log("### asyncFn: ", {data});
}

const asyncEntityFn = () => async (entity) => {
    console.log("### asyncEntityFn: ", {entity});
}
const asyncIdFn = () => async (id) => {
    console.log("### asyncIdFn: ", {id});
}
const asyncIdEntityFn = () => async (id, entity) => {
    console.log("### asyncIdEntityFn: ", {id, entity});
}

export const apiHttpInitFn = () => ({
    http: {
        get: asyncOpDataFn(),
        post: asyncOpDataFn(),
        put: asyncOpDataFn(),
        delete: asyncOpFn(),
    },
    get: asyncOpDataFn(),
    post: asyncOpDataFn(),
    put: asyncOpDataFn(),
    getForData: asyncOpDataFn(),
    postForData: asyncOpDataFn(),
    putForData: asyncOpDataFn(),
    destroy: asyncOpDataFn(),
    findById: asyncOpDataFn(),
    findAll: asyncOpFn(),
    searchCustom: asyncDataFn(),
    search: asyncDataFn(),
    create: asyncFn(),
    update: asyncFn(),
    crud: () => ({
        http: {
            get: asyncOpDataFn(),
            post: asyncOpDataFn(),
            put: asyncOpDataFn(),
            delete: asyncOpFn(),
        },
        endpoint: '',
        url: op => op,
        create: asyncEntityFn(),
        update: asyncIdEntityFn(),
        destroy: asyncIdFn(),
        findById: asyncIdFn(),
        findAll: asyncFn(),
        search: asyncFn(),
        searchCustom: asyncFn(),
    })
});

export const apiHttpFn = (_axios, urlFn, name) => {
    let apiHttp = http(_axios, urlFn);
    const _crudPartial = crudPartial(apiHttp);
    const _crud = crud(_crudPartial);
    const {findById, findAll, create, update, search, searchCustom, destroy} = _crudPartial;

    return {
        ...apiHttp, findById, findAll, create, update, search, searchCustom,
        destroy, crud: _crud, callHttp, name, urlFn, url: urlFn("")
    };
}
