import {Box, List, ListSubheader, useTheme} from "@mui/material";

import NavItem from "./NavItem";
import {useNavConfig} from "./useNavConfig";
import {useRoutesMatcher} from "../../../shared/hooks/useRoutes";
import useCollapseDrawer from "../../../shared/hooks/useCollapseDrawer";


export default function NavSection() {
    const theme = useTheme();
    const navConfig = useNavConfig();
    const {routeMatch} = useRoutesMatcher();

    const {isCollapse} = useCollapseDrawer();
    const isShow = !isCollapse;

    return (
        <Box sx={{flexGrow: 1, overflowY: "auto"}}>
            {navConfig.map((list) => {
                const {subheader, items} = list;
                return (
                    <List key={subheader} disablePadding>
                        {isShow && (
                            <ListSubheader
                                disableSticky disableGutters
                                sx={{
                                    ...theme.typography.overline,
                                    color: theme.palette.text.primary,
                                    marginTop: 3, marginBottom: 2, paddingLeft: 3,
                                }}
                            >
                                {subheader}
                            </ListSubheader>
                        )}
                        {items.map((item) => (
                            <NavItem key={item.title} item={item} isShow={isShow} routeMatch={routeMatch}/>
                        ))}
                    </List>
                );
            })}
        </Box>
    );
}

