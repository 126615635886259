import {forwardRef} from "react";
import {Box} from "@mui/material";
import PropTypes from "prop-types";

import Page403 from "./Page403";

// ----------------------------------------------------------------------

// eslint-disable-next-line react/display-name
export const SecuredPage = forwardRef(({children, title = '', authorize, ...other}, ref) => {

    if (!authorize) return <Page403/>;

    return (
        <Page ref={ref} title={title} {...other}>
            {children}
        </Page>
    );
});

// eslint-disable-next-line react/display-name
const Page = forwardRef(({children, title = '', ...other}, ref) => {

    return (
        <Box ref={ref} {...other}>
            <title>{title}</title>
            {children}
        </Box>
    );
});

Page.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string
};

export default Page;
